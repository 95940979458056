import React, { useEffect, useState } from "react";
import Bars from "react-loader-spinner/dist/loader/Bars";
import { useSelector } from "react-redux";
import { Filters } from "../../../actions/types";
import {
  selectProductsFilters,
  selectProductsLoadingStatus,
  selectProductsSettings,
  removeEmptyValuesFromObject,
} from "../products_list/productsSlice";
import CheckboxFilters from "./CheckboxFilters";
import Slider from "./Slider";

declare namespace I18n {
  function t(s: string): string;
}

export interface FilterCategory {
  by_area: [];
  by_categories: [];
  by_number_of_rooms: [];
  by_quality: [];
  by_thickness: [];
  height_range: [];
  price_range: [];
  start_ranges: [];
  width_range: [];
}

export interface LeftPanelProps {
  currencySymbol?: string;
  isCatalog: boolean;
}

const LeftPanel = (props: LeftPanelProps) => {
  const sections = {
    price: true,
    quantity_of_rooms: true,
    internal_area: true,
    wall_thickness: true,
    quality: true,
    width: true,
    length: true,
  };

  const [openSection, setOpenSection] = useState(sections);
  const [filters, setFilters] = useState<Filters | null>(null);
  const [filtersEmpty, setFiltersEmpty] = useState(true);
  const [showMoreRooms, setShowMoreRooms] = useState(false);
  const [showInternalArea, setShowInternalArea] = useState(false);
  const [showThickness, setShowThickness] = useState(false);
  const [showQuality, setShowQuality] = useState(false);
  const selectFilters = useSelector(selectProductsFilters);
  const settings = useSelector(selectProductsSettings);

  const loadingProductsStatus = useSelector(selectProductsLoadingStatus);

  const getFilters = () => {
    setFilters(selectFilters);
  };

  function clearClicked() {
    console.log("clearClicked");
    //@ts-ignore TG
    window.location = window.location.pathname;
  }

  useEffect(() => {
    getFilters();
  }, [selectFilters]);

  useEffect(() => {
    if (!filters) {
      setFiltersEmpty(true);
      return;
    }

    const notEmptyValues = removeEmptyValuesFromObject(settings.q);
    const somethingSelected = Object.keys(notEmptyValues).length > 0;
    setFiltersEmpty(!somethingSelected);
  }, [filters]);

  const Spinner = () => {
    return (
      <div className="loader-wrapper">
        <Bars
          height="120"
          width="120"
          color="#446084"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass="w-100 justify-content-around"
          visible={true}
        />
      </div>
    );
  };

  return (
    <>
      <div
        role="main"
        className="main shop"
        style={{
          opacity: !filters || loadingProductsStatus === "loading" ? 0.3 : 1,
        }}
      >
        <div className="accordion">
          {!props.isCatalog && (
            <section className="toggle">
              <div className="single-filter-header">
                <strong>
                  <a className="toggle-title" data-id="price_lteq">
                    {I18n.t("shop.price")}:
                  </a>
                </strong>
                <i
                  className={
                    "fa " +
                    (openSection.price ? "fa-chevron-up" : "fa-chevron-down")
                  }
                  role="button"
                  onClick={() =>
                    setOpenSection({
                      ...openSection,
                      price: !openSection.price,
                    })
                  }
                  data-toggle="collapse"
                  data-target="#toggle-content-price"
                  aria-expanded="false"
                  aria-controls="toggle-content-price"
                ></i>
              </div>
              <div
                className="collapse show toggle-content text-center pt-2"
                id="toggle-content-price"
              >
                <Slider
                  filters={filters}
                  currencySymbol={props.currencySymbol}
                  type="price"
                />
              </div>
              <hr />
            </section>
          )}

          <section className="toggle">
            <div className="single-filter-header">
              <strong>
                <a className="toggle-title" data-id="quantity_of_rooms_eq">
                  {I18n.t("shop.specs.quantity_of_rooms")}
                </a>
              </strong>
              <i
                className={
                  "fa " +
                  (openSection.quantity_of_rooms
                    ? "fa-chevron-up"
                    : "fa-chevron-down")
                }
                onClick={() =>
                  setOpenSection({
                    ...openSection,
                    quantity_of_rooms: !openSection.quantity_of_rooms,
                  })
                }
                aria-hidden="true"
                data-toggle="collapse"
                data-target="#filter-quantity-of-rooms"
                aria-expanded="false"
                aria-controls="filter-quantity-of-rooms"
              ></i>
            </div>
            <div
              className="collapse show toggle-content text-center pt-2"
              id="filter-quantity-of-rooms"
            >
              <CheckboxFilters
                settingsKey="quantity_of_rooms_eq"
                filter={filters && filters["by_number_of_rooms"]}
                showMore={showMoreRooms}
                setShowMore={setShowMoreRooms}
              />
            </div>
          </section>

          <hr />

          <section className="toggle">
            <div className="single-filter-header">
              <strong>
                {" "}
                <a className="toggle-title" data-id="ground_surface_eq">
                  {I18n.t("shop.specs.internal_area")}
                </a>{" "}
              </strong>
              <i
                className={
                  "fa " +
                  (openSection.internal_area
                    ? "fa-chevron-up"
                    : "fa-chevron-down")
                }
                onClick={() =>
                  setOpenSection({
                    ...openSection,
                    internal_area: !openSection.internal_area,
                  })
                }
                aria-hidden="true"
                data-toggle="collapse"
                data-target="#filter-ground-surface"
                aria-expanded="false"
                aria-controls="filter-ground-surface"
              ></i>
            </div>
            <div
              className="collapse show toggle-content text-center pt-2"
              id="filter-ground-surface"
            >
              <CheckboxFilters
                settingsKey="ground_surface_gteq"
                filter={filters && filters["by_area"]}
                showMore={showInternalArea}
                setShowMore={setShowInternalArea}
              />
            </div>
          </section>

          <hr />

          <section className="toggle">
            <div className="single-filter-header">
              <a className="toggle-title" data-id="walls_thickness_eq">
                <strong>{I18n.t("shop.specs.wall_thickness")}</strong>{" "}
              </a>
              <i
                className={
                  "fa " +
                  (openSection.wall_thickness
                    ? "fa-chevron-up"
                    : "fa-chevron-down")
                }
                onClick={() =>
                  setOpenSection({
                    ...openSection,
                    wall_thickness: !openSection.wall_thickness,
                  })
                }
                aria-hidden="true"
                data-toggle="collapse"
                data-target="#filter-wall-thickness"
                aria-expanded="false"
                aria-controls="filter-wall-thickness"
              ></i>
            </div>
            <div
              className="collapse show toggle-content text-center pt-2"
              id="filter-wall-thickness"
            >
              <CheckboxFilters
                settingsKey="walls_thickness_eq"
                filter={filters && filters["by_thickness"]}
                showMore={showThickness}
                setShowMore={setShowThickness}
              />
            </div>
          </section>

          {false && (
            <section className="toggle">
              <div className="single-filter-header">
                <a className="toggle-title" data-id="quality_eq">
                  <strong>{I18n.t("shop.specs.quality")}</strong>
                </a>
                <i
                  className={
                    "fa " +
                    (openSection.quality ? "fa-chevron-up" : "fa-chevron-down")
                  }
                  onClick={() =>
                    setOpenSection({
                      ...openSection,
                      quality: !openSection.quality,
                    })
                  }
                  aria-hidden="true"
                  data-toggle="collapse"
                  data-target="#filter-quality"
                  aria-expanded="false"
                  aria-controls="filter-quality"
                ></i>
              </div>
              <div
                className="collapse show toggle-content text-center pt-2"
                id="filter-quality"
              >
                <CheckboxFilters
                  settingsKey="quality_eq"
                  filter={filters && filters["by_quality"]}
                  showMore={showQuality}
                  setShowMore={setShowQuality}
                />
              </div>
            </section>
          )}
          <hr />

          <section className="toggle">
            <div className="single-filter-header">
              <a className="toggle-title" data-id="external_cabin_width_lteq">
                <strong>{I18n.t("shop.width")}</strong>
              </a>
              <i
                className={
                  "fa " +
                  (openSection.width ? "fa-chevron-up" : "fa-chevron-down")
                }
                onClick={() =>
                  setOpenSection({ ...openSection, width: !openSection.width })
                }
                aria-hidden="true"
                data-toggle="collapse"
                data-target="#toggle-content-external_cabin_width"
                aria-expanded="true"
                aria-controls="toggle-content-external_cabin_width"
              ></i>
            </div>
            <div
              className="collapse show toggle-content text-center pt-2"
              id="toggle-content-external_cabin_width"
            >
              <Slider filters={filters} type="width" />
            </div>
          </section>

          <hr />

          <section className="toggle">
            <div className="single-filter-header">
              <a className="toggle-title" data-id="external_cabin_height_lteq">
                <strong>{I18n.t("shop.length")}</strong>
              </a>
              <i
                className={
                  "fa " +
                  (openSection.length ? "fa-chevron-up" : "fa-chevron-down")
                }
                onClick={() =>
                  setOpenSection({
                    ...openSection,
                    length: !openSection.length,
                  })
                }
                aria-hidden="true"
                data-toggle="collapse"
                data-target="#toggle-content-external_cabin_height"
                aria-expanded="true"
                aria-controls="toggle-content-external_cabin_height"
              ></i>
            </div>
            <div
              className="collapse show toggle-content text-center pt-2"
              id="toggle-content-external_cabin_height"
            >
              <Slider filters={filters} type="length" />
            </div>
          </section>
        </div>
      </div>

      <section className="d-flex">
        {!filtersEmpty && (
          <a
            href="#"
            className="btn btn--blue"
            style={{ marginTop: 10 }}
            onClick={() => clearClicked()}
          >
            {I18n.t("shop.clear")}
          </a>
        )}
      </section>
    </>
  );
};

export default LeftPanel;
