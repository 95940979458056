import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AdditionData, AdditionOptionData } from "../../../actions/types";
import { setSelectedOptions as setSelectedOptionsAction } from "../products/productSlice";
import UpsaleAddition from "./UpsaleAddition";

declare namespace I18n {
  function t(s: string): string;
}
declare function prefixURL(url: string): string;

const Upsale = ({
  project_id,
  currencySymbol,
  qty,
  showVat,
  montageSum,
  isCatalog,
}) => {
  const [additions, setAdditions] = useState(new Array<AdditionData>()),
    [selectedOptions, setSelectedOptions] = useState(
      new Array<AdditionOptionData>()
    ),
    [hasMontage, setHasMontage] = useState(false),
    [collapsing, setCollapsing] = useState<boolean[]>(new Array<boolean>()),
    [expandAll, setExpandAll] = useState<boolean>(false),
    dispatch = useDispatch();

  function getPathUptoProjectName() {
    const href = window.location.pathname;
    const path_without_params = href.split("?")[0];
    return path_without_params.substring(
      0,
      path_without_params.lastIndexOf("/")
    );
  }

  const loadAdditions = () => {
      Axios({
        method: "GET",
        url: prefixURL(
          `${getPathUptoProjectName()}/${project_id}/project.json`
        ),
        headers: {
          accept: "application/json",
        },
      })
        .then((res) => {
          setAdditions(res.data.additions);
          setSelectedOptions(res.data.additions.map((a) => a.options[0]));
          if (isCatalog) {
            setExpandAll(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isChoosen = (addon_code: string) => {
      const ix = additions.findIndex((obj) => obj.code === addon_code);
      if (ix < 0) return false;
      if (selectedOptions.length - 1 < ix) return false;

      return !selectedOptions[ix].is_zero;
    },
    isEnabled = (addon: AdditionData) => {
      if (addon.code === "roof_tiles") {
        return !isChoosen("metal_roof");
      } else if (addon.code === "metal_roof") {
        return !isChoosen("roof_tiles");
      }

      return true;
    },
    optionSelected = (addon: AdditionData, opt: AdditionOptionData) => {
      const ix = additions.indexOf(addon);
      setSelectedOptions([
        ...selectedOptions.slice(0, ix),
        opt,
        ...selectedOptions.slice(ix + 1),
      ]);
    },
    setCollapse = (idx: number) => {
      setCollapsing((current) =>
        current.map((obj, i) => {
          if (i === idx) {
            return !collapsing[i];
          }
          return obj;
        })
      );
    },
    mapAdditionToCollapse = (state: boolean) => {
      return additions.map((x) => {
        return state;
      });
    };

  //
  useEffect(() => {
    const montage = additions.find((o) => o.code === "montage");
    setHasMontage(montage ? true : false);
  }, [additions]);

  useEffect(() => {
    loadAdditions();
  }, []);

  useEffect(() => {
    const emptyCollapsing = mapAdditionToCollapse(false);
    setCollapsing(emptyCollapsing);
  }, [additions]);

  useEffect(() => {
    if (selectedOptions) {
      dispatch(setSelectedOptionsAction(selectedOptions));
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (expandAll) {
      setCollapsing(mapAdditionToCollapse(true));
    } else {
      setCollapsing(mapAdditionToCollapse(false));
    }
  }, [expandAll]);

  if (!additions.length) {
    return <></>;
  }

  return (
    <div className="featured-boxes mt-2">
      <div className="product__sidebar-hr"></div>
      <div className="row">
        <div className="col">
          <div className="featured-box featured-box-primary text-left mt-2">
            <div className="box-content">
              <div className="d-flex justify-content-between">
                <h6 className="color-primary font-weight-semibold text-4 text-uppercase mb-3">
                  {isCatalog
                    ? I18n.t("shop.show_extras")
                    : I18n.t("shop.choose_extras")}
                </h6>
                <small
                  className="float-right expand-all-btn"
                  onClick={() => setExpandAll((current) => !current)}
                >
                  {expandAll
                    ? I18n.t("shop.collapse_all")
                    : I18n.t("shop.expand_all")}{" "}
                  <i
                    className={`fa fa-arrow-${expandAll ? "up" : "down"}`}
                    aria-hidden="true"
                  ></i>
                </small>
              </div>
              <div className="row">
                {additions.map((addition, index) => (
                  <UpsaleAddition
                    qty={qty}
                    key={`${index}`}
                    index={index}
                    addition={addition}
                    hasMontage={hasMontage}
                    montageSum={montageSum}
                    isEnabled={isEnabled(addition)}
                    optionSelected={optionSelected}
                    currencySymbol={currencySymbol}
                    collapseShow={collapsing[index]}
                    setCollapse={setCollapse}
                    showVat={showVat}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upsale;
