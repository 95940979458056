// TODO: do usuniecia i zastapienia pozniej danymi z serwera
const _products = {
  filters: {
    price_range: [895, 46212],
    width_range: ["0.0", "10.5"],
    height_range: ["0.0", "22.0"],
    by_categories: [
      ["", 268, "All", "/search", true],
      [1522, 35, "Shed/storage cabins", "/category/shed-storage-cabins", false],
      [1523, 49, "Garden cabins", "/category/garden-cabins", false],
      [1524, 23, "Summer house/chalet", "/category/summer-house-chalet", false],
      [1525, 11, "Garages/carports", "/category/garages-carports", false],
      [1597, 9, "Home office/studio", "/category/home-office-studio", false],
      [1598, 14, "Villa/country house", "/category/villa-country-house", false],
      [1599, 9, "Pergolas", "/category/pergolas", false],
    ],
    by_number_of_rooms: [
      [1, 116, false],
      [2, 40, false],
      [3, 30, false],
      [4, 33, false],
      [5, 10, false],
      [6, 9, false],
      [7, 6, false],
      [8, 4, false],
      [9, 2, false],
      [11, 2, false],
    ],
    by_area: [
      [
        {
          value_pairs: [
            ["0.0", 76],
            ["2.0", 2],
            ["3.0", 2],
            ["3.6", 2],
            ["3.8", 1],
            ["4.0", 4],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["4.6", 2],
            ["5.5", 2],
            ["6.0", 4],
            ["6.4", 2],
            ["6.7", 2],
            ["7.0", 6],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["8.0", 4],
            ["8.7", 2],
            ["9.0", 6],
            ["10.0", 6],
            ["10.12", 1],
            ["11.0", 2],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["12.0", 6],
            ["12.3", 1],
            ["14.0", 6],
            ["16.0", 7],
            ["18.0", 2],
            ["21.0", 7],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["22.0", 8],
            ["23.0", 2],
            ["24.0", 16],
            ["25.0", 8],
            ["26.0", 2],
            ["27.0", 4],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["28.0", 4],
            ["30.0", 2],
            ["32.0", 2],
            ["32.3", 5],
            ["32.6", 2],
            ["33.0", 6],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["34.8", 3],
            ["35.0", 5],
            ["41.0", 2],
            ["43.0", 2],
            ["46.0", 4],
            ["48.0", 4],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["51.0", 2],
            ["56.0", 2],
            ["65.0", 2],
            ["66.0", 2],
            ["68.64", 2],
            ["73.0", 2],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["80.0", 2],
            ["81.0", 2],
            ["83.0", 2],
            ["85.31", 2],
            ["87.0", 2],
            ["90.0", 4],
          ],
        },
        false,
      ],
      [
        {
          value_pairs: [
            ["96.0", 2],
            ["100.0", 2],
            ["132.0", 2],
          ],
        },
        false,
      ],
    ],
    by_thickness: [
      ["28", 0, false],
      ["44", 0, false],
      ["68", 0, false],
      ["58", 0, false],
      ["88", 0, false],
      ["132", 0, false],
    ],
    by_quality: [
      [0, 106, false],
      [1, 162, false],
    ],
    start_ranges: {
      price: [895, 46212],
      width: ["0.0", "10.5"],
      height: ["0.0", "22.0"],
    },
  },
  products: [
    {
      id: 16294,
      category_names: "Shed/storage cabins, Gable roof",
      img: "/uploads/media_file/file/3621/39-thumb-Luknė_3x3_28mm__2__96843.jpg",
      url: "http://main.localhost:3000/products/lukne-3-x-3-m-28-mm",
      name: "28 Luknė 3x3",
      product_price: "2 987 ",
      currency_symbol: "€",
    },
    {
      id: 18288,
      category_names: "Garden cabins, Gable roof",
      img: "/uploads/media_file/file/4580/39-thumb-grutas-perspective_88040.jpg",
      url: "http://main.localhost:3000/products/grutas-3x13-44mm",
      name: "44 Grutas 3x13-Premium",
      product_price: "7 882 ",
      currency_symbol: "€",
    },
    {
      id: 24190,
      category_names: "Garden cabins, Gable roof",
      img: "/uploads/media_file/file/3484/39-thumb-Hakan_B_7x7_19__1__01856.jpg",
      url: "http://main.localhost:3000/products/hakan-b-7-x-7-19-m-44-mm",
      name: "44 Hakan B 7x7,19-Premium",
      product_price: "9 028 ",
      currency_symbol: "€",
    },
    {
      id: 25470,
      category_names: "Home office/studio",
      img: "/uploads/media_file/file/3808/39-thumb-Lille_6x3_5_dazyta-naktis_7_-_Photo_53292.jpg",
      url: "http://main.localhost:3000/products/lille",
      name: "44 Lille 6x3,5",
      product_price: "7 238 ",
      currency_symbol: "€",
    },
    {
      id: 26196,
      category_names: "Garden cabins, Gable roof",
      img: "/uploads/media_file/file/4583/39-thumb-Helmand_3x9__2__58222.jpg",
      url: "http://main.localhost:3000/products/sodo-namas-helmand-3x9m-44mm",
      name: "44 Helmand 3x9-Premium",
      product_price: "5 502 ",
      currency_symbol: "€",
    },
    {
      id: 26197,
      category_names: "Summer house/chalet, 0-40 m2",
      img: "/uploads/media_file/file/4591/39-thumb-Alma_7_05x8__1__03730.jpg",
      url: "http://main.localhost:3000/products/vasarnamis-alma-7x8m-44mm",
      name: "44 Alma 7,05x8-Premium",
      product_price: "8 952 ",
      currency_symbol: "€",
    },
    {
      id: 26198,
      category_names: "Summer house/chalet, 40-60 m2",
      img: "/uploads/media_file/file/4603/39-thumb-Almeria_6x8_87__1__03236.jpg",
      url: "http://main.localhost:3000/products/vasarnamis-almeria-6x8-87-68mm",
      name: "68 Almeria 6x8,87",
      product_price: "13 913 ",
      currency_symbol: "€",
    },
    {
      id: 26199,
      category_names: "Shed/storage cabins, Gable roof",
      img: "/uploads/media_file/file/4498/39-thumb-Alva_3x4_renderiui_-_nupjauti_kampukai__1__84429.jpg",
      url: "http://main.localhost:3000/products/28-Alva-3x4",
      name: "28 Alva 3x4",
      product_price: "2 024 ",
      currency_symbol: "€",
    },
    {
      id: 26200,
      category_names: "Shed/storage cabins, Gable roof",
      img: "/uploads/media_file/file/4491/39-thumb-Alva_4x4__1__36959.jpg",
      url: "http://main.localhost:3000/products/medinis-namelis-alva-4x4-28mm",
      name: "28 Alva 4x4",
      product_price: "2 411 ",
      currency_symbol: "€",
    },
  ],
};

export default _products;
