import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ISelectOption } from "../../../actions/types";

declare namespace I18n {
  function t(s: string): string;
}

export interface SelectorDisabledCountryRegionProps {
  country: string;
  region: string;
}

const SelectorDisabledCountryRegion = (
  props: SelectorDisabledCountryRegionProps
) => {
  const country: ISelectOption = { label: props.country, value: props.country };
  const region: ISelectOption = { label: props.region, value: props.region };

  return (
    <div className="row">
      <input
        required
        type="hidden"
        value={props.country}
        name="order[transport_cost_country]"
        id="order_transport_cost_country"
      />
      <input
        required
        type="hidden"
        value={props.region}
        name="order[transport_cost_region]"
        id="order_transport_cost_region"
      />
      <Select
        isDisabled={true}
        className="col-12 col-sm-6 col-md-6 col-lg-6 my-1"
        placeholder={`${I18n.t("shop.transport_country")} **`}
        options={[country]}
        defaultValue={country}
        noOptionsMessage={() => I18n.t("shop.transport_country_empty")}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      <Select
        isDisabled={true}
        className="col-12 col-sm-6 col-md-6 col-lg-6 my-1"
        placeholder={`${I18n.t("shop.transport_region")} **`}
        options={[region]}
        defaultValue={region}
        noOptionsMessage={() => I18n.t("shop.transport_region_empty")}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  );
};

export default SelectorDisabledCountryRegion;
