import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ByAreaClass } from "../../../actions/types";
import {
  getProductsFromApi,
  selectProductsFilters,
  selectProductsSettings,
  setSettings,
} from "../products_list/productsSlice";

interface CheckboxFiltersProps {
  filter:
  | Array<[number, number, string, boolean]>
  | Array<[number, number, boolean]>
  | Array<Array<boolean | ByAreaClass>>
  | Array<[string, number, boolean]>
  | Array<[number, number, boolean]>
  | null;
  showMore: boolean;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
  settingsKey:
  | "quantity_of_rooms_eq"
  | "ground_surface_gteq"
  | "walls_thickness_eq"
  | "quality_eq";
}

// const hashSettingsKeyParamsKey = {
//   quantity_of_rooms_eq: ,
//   by_area: ,
//   by_thickness: ,
//   by_quality: ,
// }

const returnArrayWithoutItem = (array: string[], value: string) => {
  if (!array.length) return [];
  return array.filter((item) => item !== value);
};

const HOW_MANY_FILTERS_DEFAULT = 4;

const CheckboxFilters = (props: CheckboxFiltersProps) => {
  const dispatch = useDispatch();
  const selectFilters = useSelector(selectProductsFilters);
  const selectSettings = useSelector(selectProductsSettings);
  const onChangeCheckbox = (value: string, checked: boolean) => {
    if (checked) {
      // when check
      const arr = selectSettings.q[props.settingsKey]
        ? selectSettings.q[props.settingsKey].slice()
        : [];
      arr.push(value);
      dispatch(
        setSettings({
          ...selectSettings,
          q: {
            ...selectSettings.q,
            [props.settingsKey]: Array.from(new Set(arr)),
          },
          page: 1,
        })
      );
    } else {
      // when uncheck
      const arr = selectSettings.q[props.settingsKey].slice() || [];
      dispatch(
        setSettings({
          ...selectSettings,
          q: {
            ...selectSettings.q,
            [props.settingsKey]: returnArrayWithoutItem(arr, value),
          },
          page: 1,
        })
      );
    }
    dispatch(getProductsFromApi(""));
  };

  const formatLabel = (number: number) => {
    if (props.settingsKey === "ground_surface_gteq") {
      return `${number[0].value_pairs[0][0]} - ${number[0].value_pairs[number[0].value_pairs.length - 1][0]
        } (${sumValuePairs(number[0].value_pairs)})`;
    } else if (props.settingsKey === "quality_eq") {
      return `${variantQuality(number[0])} (${number[1]})`;
    } else {
      return `${number[0]} (${number[1]})`;
    }
  };
  return (
    <ul style={{ listStyle: "none" }} className="text-left pt-2 pl-4">
      {props.filter &&
        props.filter.map((number, index) => {
          if (index < HOW_MANY_FILTERS_DEFAULT || props.showMore) {
            return (
              <li key={`${props.settingsKey}-${index}`} style={{ fontSize: 14 }}>
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={
                    props.settingsKey !== "ground_surface_gteq"
                      ? !!number[2]
                      : !!number[1]
                  }
                  value={
                    props.settingsKey !== "ground_surface_gteq"
                      ? `${number[0]}`
                      : `${number[0].value_pairs[0][0]}:${number[0].value_pairs[
                      number[0].value_pairs.length - 1
                      ][0]
                      }`
                  }
                  id={`${props.settingsKey}-${index}`}
                  onChange={(e) =>
                    onChangeCheckbox(e.target.value, e.target.checked)
                  }
                />
                <label
                  className="mr-2"
                  htmlFor={`${props.settingsKey}-${index}`}
                >
                  {formatLabel(number)}
                </label>
              </li>
            );
          } else {
            if (index === HOW_MANY_FILTERS_DEFAULT) {
              var hiddenElementsCount =
                ((props.filter && props.filter.length) || 0) -
                HOW_MANY_FILTERS_DEFAULT;
              return (
                <li className="show-more" key={`${props.settingsKey}-${index}`}>
                  <a
                    className="shop-show-more-link"
                    onClick={() => props.setShowMore(!props.showMore)}
                  >
                    {I18n.t("shop.show_more")} ({hiddenElementsCount})...
                  </a>
                </li>
              );
            }
          }
        })}
      {props.showMore &&
        props.filter &&
        props.filter.length > HOW_MANY_FILTERS_DEFAULT ? (
        <li className="show-less">
          <a
            className="shop-show-more-link"
            onClick={() => props.setShowMore(!props.showMore)}
          >
            {I18n.t("shop.hide")}
          </a>
        </li>
      ) : null}
    </ul>
  );
};
export default CheckboxFilters;

const sumValuePairs = (arr: [string, number]) => {
  return arr.reduce((prev, curr) => {
    return prev + curr[1];
  }, 0);
};

const variantQuality = (integer: number) => {
  switch (integer) {
    case 0:
      return "Economy";
    case 1:
      return "Premium";
    default:
      return integer;
  }
};
