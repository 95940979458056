import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  vat: 0,
  net: 0,
  status: "idle",
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setCostByValue: (state, action) => {
      state.value = action.payload;
    },
    setCostVatByValue: (state, action) => {
      state.vat = action.payload;
    },
    setCostNetByValue: (state, action) => {
      state.net = action.payload;
    },
  },
});

export const { setCostByValue, setCostVatByValue, setCostNetByValue } = transactionSlice.actions;

export const selectCost = (state) => state.transaction.value;
export const selectVatCost = (state) => state.transaction.vat;
export const selectNetCost = (state) => state.transaction.net;

export default transactionSlice.reducer;
