import qs from "qs";
import React, { useEffect } from "react";
import Bars from "react-loader-spinner/dist/loader/Bars";
import { useDispatch, useSelector } from "react-redux";
import { ShopSettings } from "../../../actions/types";
import Pagination from "../pagination/Pagination";
import Product from "./Product";
import {
  getProductsFromApi,
  getProductsFromApiParams,
  ProductsState,
  selectProducts,
  selectProductsSettings,
  setSettings,
} from "./productsSlice";

interface ProductsProps {
  isCatalog: boolean;
  isB2B: boolean;
}

const Products = (props: ProductsProps) => {
  const dispatch = useDispatch();
  const products: ProductsState = useSelector(selectProducts);
  const productsSettings = useSelector(selectProductsSettings);

  const productsMap = () => {
    return products.products.map((product, i) => {
      return (
        <Product
          key={product.id}
          product={product}
          isCatalog={props.isCatalog}
          isB2B={props.isB2B}
        />
      );
    });
  };

  const selectSearch = (name: string, value: string) => {
    dispatch(setSettings({ ...products.settings, [name]: value, page: 1 }));
    dispatch(getProductsFromApi(""));
  };

  const setUrl = () => {
    const searchObject: any = qs.parse(window.location.search.substring(1), {
      parseArrays: true,
    });
    const typedSearchObject: ShopSettings = searchObject;
    const obj = { ...productsSettings, ...typedSearchObject };
    const objModified = props.isCatalog ? removePropsWithEmptyValue(obj) : obj;
    dispatch(setSettings(objModified));
    dispatch(getProductsFromApiParams(objModified));
  };

  useEffect(() => {
    setUrl();
  }, []);

  useEffect(() => {}, [productsSettings]);

  if (products.status === "loading" || products.status === "idle") {
    return (
      <Bars
        height="120"
        width="120"
        color="#446084"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="w-100 justify-content-around"
        visible={true}
      />
    );
  }

  return (
    <>
      <div className="row pt-9 ">
        <div className="col-md-12">
          <div className="form-group row filter-bar ">
            <div>
              <select
                name="sort_by"
                id="sort_by"
                className="form-control sort-by-select"
                onChange={(e) => selectSearch(e.target.name, e.target.value)}
                value={products.settings.sort_by || ""}
              >
                <option value="">{I18n.t("shop.sort_by")}</option>
                <option value="name_asc">
                  {I18n.t("shop.sort_by_name")} ({I18n.t("shop.asc")})
                </option>
                <option value="name_desc">
                  {I18n.t("shop.sort_by_name")} ({I18n.t("shop.desc")})
                </option>
                <option value="price_asc">
                  {I18n.t("shop.sort_by_price")} ({I18n.t("shop.asc")})
                </option>
                <option value="price_desc">
                  {I18n.t("shop.sort_by_price")} ({I18n.t("shop.desc")})
                </option>
              </select>
            </div>
            <label className="mr-3 mb-0">{I18n.t("shop.show")}:</label>
            <div className="mr-3">
              <select
                name="items"
                id="items"
                className="form-control items-per-page"
                onChange={(e) => selectSearch(e.target.name, e.target.value)}
                value={products.settings.items}
              >
                <option value="9">9</option>
                <option value="12">12</option>
                <option value="18">18</option>
                <option value="36">36</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row products product-thumb-info-list">
        {products.status === "success" && productsMap()}
      </div>
      <Pagination />
    </>
  );
};

const removePropsWithEmptyValue = (a) => {
  const q = a.q;
  const filteredQ = Object.fromEntries(
    Object.entries(q).filter(([key, value]) => value !== "")
  );

  const filteredA = {
    ...a,
    q: filteredQ,
  };

  return filteredA;
};

export default Products;
