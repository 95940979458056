import { configureStore } from "@reduxjs/toolkit";
import transactionReducer from "./transactionSlice";
import checkboxReducer from "./checkboxSlice";

export const store = configureStore({
  reducer: {
    transaction: transactionReducer,
    customShipping: checkboxReducer,
  },
});
