import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Provider, useDispatch, useSelector } from "react-redux";
import CatalogRedirectModal from "./CatalogRedirectModal";
import { selectShowModal, setShowModal } from "./catalogSlice";
import { CatalogAppProps } from "./CatalogApp";
import styled from "styled-components";

const FloatingIcon = (props: CatalogAppProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectShowModal);
  const qS = document.querySelector("meta[name='csrf-token']");
  const csrf = qS ? qS.getAttribute("content") : "";

  const setIsOpen = (state: boolean) => {
    dispatch(setShowModal(state));
  };

  const ModalStyle = styled.div`
    width: 95%;
    @media (min-width: 640px) {
      width: 95%;
    }
    @media (min-width: 768px) {
      width: 80%;
    }
    @media (min-width: 1024px) {
      width: 65%;
    }
    @media (min-width: 1280px) {
      width: 50%;
    }
    @media (min-width: 1536px) {
      width: 40%;
    }
  `;

  const OverlayStyle = styled.div`
    position: "fixed";
  `;

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={modalStyles}
        ariaHideApp={false}
        contentElement={(props, children) => (
          <ModalStyle {...props}>{children}</ModalStyle>
        )}
        overlayElement={(props, contentElement) => (
          <OverlayStyle {...props}>{contentElement}</OverlayStyle>
        )}
      >
        <CatalogRedirectModal setIsOpen={setIsOpen} {...props} />
      </ReactModal>
      {/* <div
        id="floating-redirect-icon"
        className="animate__animated animate__backInDown"
      >
        <i
          className="fa fa-location-arrow"
          aria-hidden="true"
          onClick={() => setIsOpen(true)}
        ></i>
      </div> */}
    </>
  );
};

export default FloatingIcon;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "66%",
  },
  overlay: {
    position: "fixed",
  },
};
