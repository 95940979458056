import React, { useEffect, useState } from "react";
import FrontProduct from "./FrontProduct";

export interface PromotedProductsProps {
  default_images: ImagesParams[];
  products: ProductParams[];
  currency: string;
  products_url: string;
  isCatalog: boolean;
  isB2B: boolean;
}

export interface ProductParams {
  id: number | null;
  name: string | null;
  price: string | null;
  slug: string | null;
  preview: string | null;
}

export interface ImagesParams {
  id: number | null;
  image_url: string | null;
}

export interface ProjectParams {
  id: number | null;
  name: string | null;
  price: string | null;
  slug: string | null;
  preview: string | null;
  image_url: string | null;
}

const PromotedProducts = (props: PromotedProductsProps) => {
  const [productParams, setProductParams] = useState<ProjectParams[]>([]);

  useEffect(() => {
    var merged: ProjectParams[] = [];
    props.products.map((p, id) => {
      let obj: ProjectParams = {
        id: p.id,
        name: p.name,
        price: p.price,
        slug: p.slug,
        preview: p.preview,
        image_url: props.default_images[id].image_url,
      };
      merged.push(obj);
    });

    setProductParams(merged);
  }, [props.products]);
  return (
    <div className="product-list">
      <div className="container contener_with_margin">
        <div className="product-list__inner">
          {productParams !== null
            ? productParams.map((product) => {
                return (
                  <FrontProduct
                    key={product.id}
                    product={product}
                    currency={props.currency}
                    url={props.products_url}
                    isCatalog={props.isCatalog}
                    isB2B={props.isB2B}
                  />
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default PromotedProducts;
