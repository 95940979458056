import React, { useState } from "react";
import Select from "react-select";
import { ISelectOption } from "../../../actions/types";
import { RegionPickerAppProps } from "./RegionPickerApp";

const propsToSelectOption = (props: RegionPickerAppProps): ISelectOption[] => {
  return props.domainsInfos.map((d) => {
    return { value: d.url, label: d.country };
  });
};

const propsToCurrentShop = (props: RegionPickerAppProps): ISelectOption => {
  return {
    value: props.countryDomain.domain_name,
    label: props.countryDomain.country,
  };
};

const RegionPicker = (props: RegionPickerAppProps) => {
  const [currentShop, setCurrentShop] = useState<ISelectOption>(
      propsToCurrentShop(props)
    ),
    [shops, setShops] = useState<ISelectOption[]>(propsToSelectOption(props));

  const onClickGoShopping = (shop: ISelectOption) => {
    window.location.href = shop.value;
  };
  return (
    <div className="region-picker">
      <div className="test">
        <a
          className="website-link"
          onClick={() => onClickGoShopping(currentShop)}
        >
          <span className="sr-only">Go shopping on placeholder</span>{" "}
          <span className="go-shopping" aria-hidden="true">
            {I18n.t("shop.go_shopping")}
          </span>{" "}
          <span className="arrow-container">
            <svg
              width="1.5em"
              height="1.5em"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="right-arrow"
            >
              <path
                fill="currentColor"
                d="M4,12l8,8,1.41-1.41L7.83,13H20V11H7.83l5.59-5.59L12,4Z"
              ></path>
            </svg>
          </span>
        </a>
        <hr></hr>
        {I18n.t("shop.online_store")}: {currentShop.label}
        <div className="search-region w-100">
          <Select
            options={shops}
            placeholder={I18n.t("shop.search_place")}
            value={currentShop}
            onChange={(e: ISelectOption) => {
              setCurrentShop(e);
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegionPicker;
