import React from "react";
import { Provider } from "react-redux";
import { store } from "../products/store";
import RegionPicker from "./RegionPicker";

export interface RegionPickerAppProps {
  domainsInfos: DomainInfo[];
  countryDomain: CountryInfo;
}

interface CountryInfo {
  default_locale: string;
  domain_name: string;
  country: string;
}

interface DomainInfo {
  url: string;
  country: string;
}

const RegionPickerApp = (props: RegionPickerAppProps) => {
  return (
    <Provider store={store}>
      <RegionPicker {...props}/>
    </Provider>
  );
};

export default RegionPickerApp;
