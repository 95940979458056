import { ErrorMessage } from "@hookform/error-message";
import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";

interface TextFieldProps extends ControllerRenderProps {
  placeholder?: string;
  label?: string;
  className?: string;
  type?: "textarea";
  error?: boolean;
  errors?:
    | {
        [x: string]: any;
      }
    | undefined;
}
const TextField = forwardRef(
  ({ error, errors, ...props }: TextFieldProps, ref) => {
    return (
      <>
        <Label error={error} {...props} />
        <Input error={error} {...props} />
        <ErrorMessage
          errors={errors}
          name={props.name}
          render={({ message }) => <p className="error">{message}</p>}
        />
      </>
    );
  }
);

const Label = ({ error, ...props }: TextFieldProps) => {
  const errorClass = error ? "text-danger " : "";
  if (props.label === "") {
    return <></>;
  }
  if (props.label) {
    <label
      htmlFor={props.name}
      className={`${errorClass}font-weight-bold text-2`}
    >
      {props.label}
    </label>;
  }
  return (
    <label
      htmlFor={props.name}
      className={`${errorClass}font-weight-bold text-2`}
    >
      {I18n.t(`shop.${parseNameToTranslateKey(props.name)}`)}
    </label>
  );
};

const Input = ({ error, errors, ...props }: TextFieldProps) => {
  const errorClass = error ? "border-danger " : "";
  if (props.type) {
    if (props.type === "textarea") {
      return (
        <textarea
          {...props}
          className={`${errorClass}form-control ${error ? "mb-0" : "mb-3"}${
            props.className ? ` ${props.className}` : ""
          }`}
          placeholder={props.placeholder}
          name={`order[${props.name}]`}
        ></textarea>
      );
    }
  }
  return (
    <input
      {...props}
      className={`${errorClass}form-control ${
        error ? "mb-0" : props.name === "billing_address_1" ? "mb-0" : "mb-2"
      }${props.className ? ` ${props.className}` : ""}`}
      placeholder={props.placeholder}
      name={`order[${props.name}]`}
    ></input>
  );
};

const parseNameToTranslateKey = (name: string) => {
  return name.replace("billing_", "").replace("shipping_", "");
};
export default TextField;
