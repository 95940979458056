import { createSlice } from "@reduxjs/toolkit";
import { AdditionOptionData } from "../../../actions/types";

const initialState = {
  selectedOptions: new Array<AdditionOptionData>()
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setSelectedOptions: (state, action: {payload: AdditionOptionData[], type: string}) => {
      state.selectedOptions = action.payload;
    },
  },
});

export const { setSelectedOptions } = productSlice.actions;

export const selectOptions = (state): AdditionOptionData[] => state.product.selectedOptions;

export default productSlice.reducer;
