import React from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { selectCustomShipping, setCustomShipping } from "./checkboxSlice";
import { store } from "./store";

const CustomShippingCheckbox = () => {
  const dispatch = useDispatch();
  const customShipping = useSelector(selectCustomShipping);
  const handleInputCheckboxChange = (checked: boolean) => {
    if (checked) {
      dispatch(setCustomShipping("1"));
      $(".country, .state").show();
      $("#shipping_address").show();
      // @ts-ignore
      scrollToAnimate("#shipping_address_header_anchor");
    } else {
      dispatch(setCustomShipping("0"));
      $(".country, .state").hide();
      $("#shipping_address").hide();
    }
  };
  return (
    <div className="custom-control custom-checkbox pb-3 mt-4 col-lg-12">
      <input
        name="order[shipping_address_different_than_billing]"
        type="hidden"
        value={customShipping}
      />
      <input
        id="shipbillingaddress"
        className="custom-control-input"
        type="checkbox"
        value={customShipping}
        name="order[shipping_address_different_than_billing]"
        data-validate="true"
        onChange={(e) => handleInputCheckboxChange(e.target.checked)}
      />
      <label className="custom-control-label" htmlFor="shipbillingaddress">
        {I18n.t("shop.ship_to_different_address")}
      </label>
    </div>
  );
};

const CustomShippingCheckboxApp = () => {
  return (
    <>
      <Provider store={store}>
        <CustomShippingCheckbox />
      </Provider>
    </>
  );
};
export default CustomShippingCheckboxApp;
