import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import {
  Control,
  FieldErrorsImpl,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { Provider } from "react-redux";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { CurrentUser } from "../../../../actions/types";
import { SelectCountryRegionProps } from "../SelectCountryRegion";
import { store } from "../store";
import BillingAddress from "./BillingAddress";
import ShippingAddress from "./ShippingAddress";

declare function prefixURL(url: string): string;

type ObjectShapeValues = ObjectShape extends Record<string, infer V>
  ? V
  : never;
type Shape<T extends Record<any, any>> = Partial<
  Record<keyof T, ObjectShapeValues>
>;

export interface TransactionAdressesProps {
  control: Control<TransactionAdressProps, any>;
  errors: Partial<FieldErrorsImpl<TransactionAdressProps>>;
  setValue: UseFormSetValue<TransactionAdressProps>;
  getValues: UseFormGetValues<TransactionAdressProps>;
}

export interface TransactionAdressProps {
  billing_first_name: string;
  billing_last_name: string;
  billing_email: string;
  billing_phone: string;
  billing_company: string;
  billing_address_1: string;
  billing_address_2: string;
  billing_address_3: string;
  billing_city: string;
  billing_zip_code: string;
  billing_country: string;
  billing_state: string;
  comments: string;
  shipping_first_name: string;
  shipping_last_name: string;
  shipping_company: string;
  shipping_address_1: string;
  shipping_address_2: string;
  shipping_address_3: string;
  shipping_city: string;
  shipping_zip_code: string;
  shipping_address_different_than_billing: boolean;
  transport_cost_country: string;
  transport_cost_region: string;
}

const schema = yup.object<Shape<TransactionAdressProps>>({
  billing_first_name: yup
    .string()
    .required(I18n.t("shop.transaction.errors.first_name_required")),
  billing_last_name: yup
    .string()
    .required(I18n.t("shop.transaction.errors.last_name_required")),
  billing_email: yup
    .string()
    .email(I18n.t("shop.transaction.errors.email_regex"))
    .required(I18n.t("shop.transaction.errors.email_required")),
  billing_phone: yup
    .string()
    .required(I18n.t("shop.transaction.errors.phone_required")),
  billing_address_1: yup
    .string()
    .required(I18n.t("shop.transaction.errors.address_1_required")),
  billing_address_2: yup
    .string()
    .required(I18n.t("shop.transaction.errors.address_2_required")),
  billing_city: yup
    .string()
    .required(I18n.t("shop.transaction.errors.city_required")),
  billing_zip_code: yup
    .string()
    .required(I18n.t("shop.transaction.errors.zip_code_required")),
  transport_cost_country: yup
    .string()
    .required(
      I18n.t("shop.transaction.errors.transport_cost_country_required")
    ),
  transport_cost_region: yup
    .string()
    .required(I18n.t("shop.transaction.errors.transport_cost_region_required")),
  shipping_address_different_than_billing: yup.boolean().default(false),
  shipping_first_name: yup
    .string()
    .when("shipping_address_different_than_billing", {
      is: true,
      then: yup
        .string()
        .required(I18n.t("shop.transaction.errors.first_name_required")),
      otherwise: yup.string(),
    }),
  shipping_last_name: yup
    .string()
    .when("shipping_address_different_than_billing", {
      is: true,
      then: yup
        .string()
        .required(I18n.t("shop.transaction.errors.last_name_required")),
      otherwise: yup.string(),
    }),
  shipping_address_1: yup
    .string()
    .when("shipping_address_different_than_billing", {
      is: true,
      then: yup
        .string()
        .required(I18n.t("shop.transaction.errors.address_1_required")),
      otherwise: yup.string(),
    }),
  shipping_address_2: yup
    .string()
    .when("shipping_address_different_than_billing", {
      is: true,
      then: yup
        .string()
        .required(I18n.t("shop.transaction.errors.address_2_required")),
      otherwise: yup.string(),
    }),
  shipping_city: yup.string().when("shipping_address_different_than_billing", {
    is: true,
    then: yup
      .string()
      .required(I18n.t("shop.transaction.errors.city_required")),
    otherwise: yup.string(),
  }),
  shipping_zip_code: yup
    .string()
    .when("shipping_address_different_than_billing", {
      is: true,
      then: yup
        .string()
        .required(I18n.t("shop.transaction.errors.zip_code_required")),
      otherwise: yup.string(),
    }),
});

export interface TransactionFormProps extends SelectCountryRegionProps {
  directSellingDomain: boolean;
  transportCostCountry: string;
  transportCostRegion: string;
  currentUser: CurrentUser;
}

const TransactionForm = (props: TransactionFormProps) => {
  const {
      control,
      watch,
      reset,
      handleSubmit,
      formState,
      getValues,
      setValue,
    } = useForm<TransactionAdressProps>({
      mode: "onChange",
      resolver: yupResolver(schema),
    }),
    { isValid, dirtyFields, errors } = formState,
    form = watch(),
    [formProperties, setFormProperties] = useState<TransactionAdressProps>({
      billing_first_name: props.currentUser.first_name || "",
      billing_last_name: props.currentUser.last_name || "",
      billing_email: props.currentUser.guest
        ? ""
        : props.currentUser.email || "",
      billing_phone: props.currentUser.phone || "",
      billing_company: props.currentUser.company || "",
      billing_address_1: props.currentUser.address_1 || "",
      billing_address_2: props.currentUser.address_2 || "",
      billing_address_3: props.currentUser.address_3 || "",
      billing_city: props.currentUser.city || "",
      billing_zip_code: props.currentUser.zip_code || "",
      billing_country: props.currentUser.country || "",
      billing_state: props.currentUser.state || "",
      comments: "",
      shipping_first_name: "",
      shipping_last_name: "",
      shipping_company: "",
      shipping_address_1: "",
      shipping_address_2: "",
      shipping_address_3: "",
      shipping_city: "",
      shipping_zip_code: "",
      shipping_address_different_than_billing: false,
      transport_cost_country: props.transportCostCountry,
      transport_cost_region: props.transportCostRegion,
    });

  useEffect(() => {
    reset({ ...formProperties });
  }, [formProperties, reset]);

  return (
    <>
      <Provider store={store}>
        <BillingAddress
          {...props}
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
        />
        {getValues("shipping_address_different_than_billing") && (
          <ShippingAddress
            {...props}
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
          />
        )}
        <div className="col checkout-row-btns">
          <div className="cart__submit-btn" style={{ paddingTop: "0px" }}>
            <a
              className="checkout-btn btn d-flex justify-content-around align-items-center"
              href={prefixURL("/cart")}
            >
              {I18n.t("shop.back_to_cart")}
            </a>
            <button
              disabled={!isValid}
              className="checkout-btn btn btn--ultrablue d-flex justify-content-around align-items-center"
              onClick={() => $("#submit_order_form").submit()}
              style={{ width: "296px", maxWidth: "296px" }}
            >
              {I18n.t("shop.go_to_payment")}{" "}
              <i className="fa fa-credit-card" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </Provider>
    </>
  );
};

export default TransactionForm;
