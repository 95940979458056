import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import qs from "qs";
import {
  defaultShopPagy,
  Filters,
  Pagy,
  ProductShop,
  ProductsShop,
  ShopSettings
} from "../../../actions/types";

declare function prefixURL(url: string): string;

export interface ProductsState {
  products: ProductShop[];
  status: "loading" | "success" | "error" | "idle";
  error?: string;
  filters: Filters | null;
  pagy: Pagy;
  settings: ShopSettings;
}

const initialState: ProductsState = {
  products: new Array<ProductShop>(),
  filters: null,
  status: "idle",
  pagy: defaultShopPagy,
  settings: {
    page: 1,
    items: "9",
    sort_by: null,
    q: {
      price_gteq: "",
      price_lteq: "",
      quantity_of_rooms_eq: [],
      ground_surface_gteq: [],
      walls_thickness_eq: [],
      quality_eq: [],
      by_area: [],
      external_cabin_width_gteq: [],
      external_cabin_width_lteq: [],
      external_cabin_height_gteq: [],
      external_cabin_height_lteq: [],
    },
  },
};

export function removeEmptyValuesFromObject(obj): any {
  const filteredObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        filteredObj[key] = value;
      }
    }
  }

  return filteredObj;
};


function cleanShopSettings(settings: ShopSettings):ShopSettings {
  const cleanedSettings = {page: settings.page, items: settings.items};
  if (settings.sort_by && settings.sort_by !== '') {
    cleanedSettings['sort_by'] = settings.sort_by;
  }

  cleanedSettings['q'] = removeEmptyValuesFromObject(settings.q);

  return cleanedSettings
}

export const getProductsFromApi = createAsyncThunk<
  ProductsShop,
  string,
  { state: { products: ProductsState } }
>("products/fetch", async (arg, { getState }) => {
  const cleanedSettings = cleanShopSettings(getState().products.settings);
  //const cleanedSettings = getState().products.settings;
  return await axios
    .get<ProductsShop>(
      prefixURL(`${window.location.pathname}.json`) +
      `?${qs.stringify(cleanedSettings, { arrayFormat: "brackets" })}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err: Error | AxiosError) => {
      throw err;
      // return axiosErrorHandler<ProductsShop>((res) => {
      //   if (res.type === "axios-error") return res.error.message;
      // });
    });
});

export const getProductsFromApiParams = createAsyncThunk<
  ProductsShop,
  ShopSettings,
  { state: { products: ProductsState } }
>("products/fetch", async (settings: ShopSettings, { getState }) => {

  const cleanedSettings = cleanShopSettings(getState().products.settings);
  // const cleanedSettings = getState().products.settings;


  return await axios
    .get<ProductsShop>(
      prefixURL(`${window.location.pathname}.json`) + `?${qs.stringify(cleanedSettings, { arrayFormat: "brackets" })}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err: Error | AxiosError) => {
      throw err;
      // return axiosErrorHandler<ProductsShop>((res) => {
      //   if (res.type === "axios-error") return res.error.message;
      // });
    });
});

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action: { payload: ProductShop[]; type: string }) => {
      state.products = action.payload;
    },
    setSettings: (state, action: { payload: ShopSettings; type: string }) => {

     const cleanedSettings = cleanShopSettings(action.payload);
     // const cleanedSettings = action.payload;

      window.history.pushState(
        {},
        "",
        `${window.location.pathname}?${qs.stringify(cleanedSettings, { arrayFormat: "brackets" })}`
      );
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsFromApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductsFromApi.fulfilled, (state, action) => {
        state.status = "success";
        state.products = action.payload.products;
        state.filters = action.payload.filters;
        state.pagy = action.payload.pagy;
      })
      .addCase(getProductsFromApi.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { setProducts, setSettings } = productsSlice.actions;

export const selectProducts = (state: { products: ProductsState }) => {
  return state.products;
};

export const selectProductsFilters = (state: { products: ProductsState }) => {
  return state.products.filters;
};

export const selectProductsLoadingStatus = (state: { products: ProductsState }) => {
  return state.products.status;
};

export const selectProductsSettings = (state: { products: ProductsState }) => {
  return state.products.settings;
};

export const selectProductsPagy = (state: { products: ProductsState }) => {
  return state.products.pagy;
};

export default productsSlice.reducer;
