import React from "react";
import { useSelector } from "react-redux";
import { priceWithCurrency } from "../../../actions/functions";
import { SelectCountryRegionProps } from "./SelectCountryRegion";
import { selectCost, selectVatCost } from "./transactionSlice";

declare namespace I18n {
  function t(s: string): string;
}

interface TransactionTransportPriceProps extends SelectCountryRegionProps {
  type: "price" | "select";
  price: number;
  vatPrice: number;
  showTransport: boolean;
  vatValue: number;
  isPayment: boolean;
  transport_cost_from_server?: number;
  transport_cost_vat_from_server?: number;
}

const Price = (props: TransactionTransportPriceProps) => {
  const transportCost = useSelector(selectCost);
  const transportVat = useSelector(selectVatCost);
  const grossCost = props.price;
  const vatCost = props.vatPrice;
  const symbol = props.currency;
  const costNum: number = transportCost
    ? transportCost === I18n.t("shop.transport_cost_individual")
      ? 0
      : parseFloat(transportCost.replace(",", ""))
    : 0;
  const costVatNum: number = transportVat
    ? parseFloat(transportVat.replace(",", ""))
    : 0;

  const printDeliveryCost = () => {
    return props.isPayment ? (
      <>
        <span className="mb-0">+ {I18n.t("shop.delivery_costs")}</span>
        <strong className="mb-0">
          {transportCost === 0 && !props.transport_cost_from_server
            ? I18n.t("shop.transport_cost_individual")
            : priceWithCurrency(props.transport_cost_from_server, symbol)}
        </strong>
        {transportCost !== 0 ||
          (props.transport_cost_from_server && (
            <>
              <span className="shipping-sum-vat mb-0">{`(${I18n.t(
                "shop.include_vat"
              )} ${props.vatValue}% ${I18n.t("admin.vat_tax")})`}</span>
              <strong className="shipping-sum-vat mb-0">
                {priceWithCurrency(
                  props.transport_cost_vat_from_server,
                  symbol
                )}
              </strong>
            </>
          ))}
      </>
    ) : (
      <>
        <span className="mb-0">+ {I18n.t("shop.delivery_costs")}</span>
        <strong className="mb-0">
          {!!transportCost ? `${transportCost}` : "---"}
        </strong>
        {!!transportVat &&
          transportVat !== I18n.t("shop.transport_cost_individual") && (
            <>
              <span className="shipping-sum-vat mb-0">{`(${I18n.t(
                "shop.include_vat"
              )} ${props.vatValue}% ${I18n.t("admin.vat_tax")})`}</span>
              <strong className="shipping-sum-vat mb-0">
                {priceWithCurrency(transportVat, symbol)}
              </strong>
            </>
          )}
      </>
    );
  };
  return (
    <div className="cart__total-list">
      {props.showTransport && (
        <>
          <span className="mb-0">{I18n.t("shop.order_total")}</span>
          <strong className="cart__total mb-2">
            {priceWithCurrency(grossCost, symbol)}
          </strong>
          <span className="order-sum-vat">{`(${I18n.t(
            "shop.include_vat"
          )} ${I18n.t("admin.vat_tax")})`}</span>
          <strong className="cart__total order-sum-vat">
            {priceWithCurrency(vatCost, symbol)}
          </strong>
          {printDeliveryCost()}
        </>
      )}
      <span className="mb-2 mt-4">{I18n.t("shop.total")}</span>
      <strong className="cart__total mb-2 mt-4">
        {priceWithCurrency(grossCost + costNum, symbol)}
      </strong>
      <span className="order-total-vat mb-0">{`(${I18n.t(
        "shop.include_vat"
      )} ${I18n.t("admin.vat_tax")})`}</span>
      <strong className="cart__total order-total-vat mb-0">
        {priceWithCurrency((vatCost || 0) + (costVatNum || 0), symbol)}
      </strong>
    </div>
  );
};

export default Price;
