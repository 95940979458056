import React, { useEffect, useState } from "react";
import Circles from "react-loader-spinner/dist/loader/Circles";
import { Provider, useDispatch, useSelector } from "react-redux";
import { selectProductsFilters } from "../products_list/productsSlice";
import { store } from "../products_list/store";

declare function prefixURL(url: string): string;

const Categories = () => {
  const dispatch = useDispatch();
  const selectFilters = useSelector(selectProductsFilters);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectFilters) {
      return;
    }
    const actualCategory = selectFilters.by_categories.find((cat) => !!cat[4]);
    if (actualCategory) {
      setSelectedCategory(String(actualCategory[3] || "All"));
    }
  }, [selectFilters]);

  const onChangeCategory = (category_url: string | null) => {
    if (!category_url) return;

    setLoading(true);
    window.location.href = category_url;
  };

  if (loading) {
    return (
      <div className="category-loader-wrapper">
        <Circles
          height="120"
          width="120"
          color="#446084"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass="w-100 justify-content-around"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="ibox">
      <div className="ibox-title"></div>
      <div className="ibox-content">
        {selectFilters && (
          <section className="toggle">
            <div className="toggle-content text-center pt-2">
              <select
                className="text-left pt-2 form-control"
                id="products_category_select"
                value={selectedCategory}
                onChange={(e) => onChangeCategory(e ? e.target.value : null)}
              >
                {selectFilters.by_categories.map((cat, index) => {
                  return (
                    <option
                      key={`categories-${index}-${cat[0]}`}
                      value={`${cat[3]}`}
                    >
                      {index !== 0 && "\u00A0\u00A0"} {cat[2]} ({cat[1]})
                    </option>
                  );
                })}
              </select>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

const CategoriesApp = () => {
  return (
    <>
      <Provider store={store}>
        <Categories />
      </Provider>
    </>
  );
};
export default CategoriesApp;
