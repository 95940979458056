import React from "react";
import {
  currencyNameToSymbol,
  priceWithCurrency,
} from "../../../actions/functions";

declare function prefixURL(url: string): string;

export interface FrontProductProps {
  product: ProductParams;
  currency: string;
  url: string;
  isCatalog: boolean;
  isB2B: boolean;
}

export interface ProductParams {
  id: number;
  name: string;
  price: string;
  slug: string;
  preview: string;
  image_url: string;
}

const FrontProduct = (props: FrontProductProps) => {
  return (
    <div className="col-md-6 col-lg-3 product-list__item">
      <div className="product-list__link product-list__link-height">
        <span className="product-list__badge" style={{ textAlign: "center" }}>
          {I18n.t("shop.promoted")}
        </span>
        <a href={prefixURL(props.url + "/" + props.product.slug)}>
          <img
            className="card-img-top"
            src={prefixURL(props.product.image_url)}
            alt=""
          />
        </a>
        <div className="card-body product-list__item">
          <div className="product-list__title">
            <a href={prefixURL(props.url + "/" + props.product.slug)}>
              {props.product.name}
            </a>
            <p className="product-list__info">
              {I18n.t("shop.product_no")}: {props.product.id}
            </p>
          </div>
          {!props.isCatalog && !props.isB2B && (
            <p
              className="product-list__price"
              style={{ border: "1px solid #d4dbe3" }}
            >
              {priceWithCurrency(
                props.product.price,
                currencyNameToSymbol(props.currency)
              )}
            </p>
          )}
          <a
            href={prefixURL(props.url + "/" + props.product.slug)}
            className="btn btn--blue mb-4"
            style={{ fontSize: "10px" }}
          >
            {I18n.t("shop.project_details_two")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FrontProduct;
