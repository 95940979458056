import React from "react";
import { Collapse } from "react-bootstrap";
import { AdditionData, AdditionOptionData } from "../../../actions/types";
import UpsaleAdditionOption from "./UpsaleAdditionOption";

declare namespace I18n {
  function t(s: string): string;
}

interface IUpsaleAddition {
  addition: AdditionData;
  currencySymbol: string;
  isEnabled: boolean;
  optionSelected: (addon: AdditionData, opt: AdditionOptionData) => void;
  index: number;
  collapseShow: boolean;
  setCollapse: (idx: number) => void;
  qty: number;
  showVat: boolean;
  hasMontage: boolean;
  montageSum: number;
}

const UpsaleAddition = (props: IUpsaleAddition) => {
  const addon = props.addition,
    [choosenId, setChoosenId] = React.useState(0),
    [choosenOptionId, setChoosenOptionId] = React.useState(0),
    optionSelected = (addon: AdditionData, opt: AdditionOptionData) => {
      setChoosenId(addon.id);
      setChoosenOptionId(opt.id);
      props.optionSelected(addon, opt);
    };

  if (!props.isEnabled) {
    return <></>;
  }

  return (
    <div className="col-lg-12 col-md-6 p-1">
      <div className="card h-100">
        <div className="card-header p-0" id="headingOne">
          <button
            className="btn w-100 h-100 d-flex flex-center justify-content-between align-items-center"
            style={{ padding: "0.25rem 0.5rem!important" }}
            type="button"
            onClick={() => props.setCollapse(props.index)}
            aria-controls={`collapse-${props.index}`}
            aria-expanded={props.collapseShow}
          >
            <span className="">{addon.name} </span>
            <i
              className={`fa fa-arrow-${props.collapseShow ? "up" : "down"}`}
              aria-hidden="true"
            ></i>
          </button>
        </div>
        <Collapse in={props.collapseShow}>
          <div className="card-body" id={`collapse-${props.index}`}>
            {addon.options.length > 0 && (
              <div>
                <input
                  name={`project[project_additions_attributes][${props.index}][id]`}
                  type="hidden"
                  value={choosenId}
                  className="form-check-input"
                />

                {addon.options.map((opt) => (
                  <UpsaleAdditionOption
                    qty={props.qty}
                    key={`${props.index}-${opt.id}`}
                    isChecked={choosenOptionId === opt.id}
                    index={props.index}
                    additionOption={opt}
                    addition={addon}
                    optionSelected={optionSelected}
                    currencySymbol={props.currencySymbol}
                    showVat={props.showVat}
                    hasMontage={props.hasMontage}
                    montageSum={
                      props.addition.code === "montage" && !opt.is_zero
                        ? props.montageSum
                        : 0
                    }
                  />
                ))}
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default UpsaleAddition;
