import React, { useEffect, useState } from "react";
import { store } from "./store";
import { Provider } from "react-redux";
import RightPanel, { RightPanelProps } from "./RightPanel";
import axios from "axios";
import { IFullProject } from "../../../actions/types";

interface ProductInterface extends RightPanelProps {
  productUrl: string;
};

const Product = (props: ProductInterface) => {
  const [product, setProduct] = useState<IFullProject | null>(null);

  const fetchProduct = async () => {
    const response = await axios.get<IFullProject>(props.productUrl);
    const product = response.data;
    setProduct(product);
  }

  useEffect(() => {
    fetchProduct();
  }, [])
  return (
    <>
      <Provider store={store}>
        {product && <RightPanel {...props} product={product} />}
      </Provider>
    </>
  );
};

export default Product;
