import React from "react";
import { store } from "../products_list/store";
import { Provider } from "react-redux";
import LeftPanel, { LeftPanelProps } from "./LeftPanel";

const Filters = (props: LeftPanelProps) => {
  return (
    <>
      <Provider store={store}>
        <LeftPanel {...props} />
      </Provider>
    </>
  );
};
export default Filters;
