import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect, { InputActionMeta } from "react-select/creatable";
import toastr from "toastr";
import { ISelectOption } from "../../../actions/types";
import { selectCustomShipping } from "./checkboxSlice";
import { TransactionAdressesProps } from "./checkout/TransactionForm";
import { setCostByValue, setCostVatByValue } from "./transactionSlice";

declare namespace I18n {
  function t(s: string): string;
}

declare function prefixURL(url: string): string;

export interface SelectCountryRegionProps extends TransactionAdressesProps {
  directSellingDomain: boolean;
  weight: number | null;
  paletteCount: number | null;
  lengthDm: number | null;
  currency: string;
  showTransport: boolean;
  where: "billing" | "shipping";
}

const SelectCountryRegion = (props: SelectCountryRegionProps) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState(new Array<ISelectOption>()),
    [selectedCountry, setSelectedCountry] = useState<ISelectOption | null>(
      null
    ),
    [regions, setRegions] = useState(new Array<ISelectOption>()),
    [selectedRegion, setSelectedRegion] = useState<ISelectOption | null>(null),
    [cost, setCost] = useState<string | null>(null),
    [costVat, setCostVat] = useState<string | null>(null),
    [costLoading, setCostLoading] = useState<boolean | null>(null),
    [isRegionsLoading, setIsRegionsLoading] = useState(false),
    [isCountryLoading, setSsCountryLoading] = useState(false),
    [inputValue, setInputValue] = useState(""),
    [inputValueRegion, setInputValueRegion] = useState(""),
    customShipping = useSelector(selectCustomShipping),
    handleCountryChange = (option: ISelectOption) => {
      setSelectedCountry(option);
      getRegions(option.value);
    },
    handleRegionChange = (option: ISelectOption) => {
      setSelectedRegion(option);
      getTransportCost(
        option.value,
        selectedCountry ? selectedCountry.value : ""
      );
    };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (cost && costVat) {
      dispatch(setCostByValue(cost));
      dispatch(setCostVatByValue(costVat));
      if (props.showTransport) {
        toastr.info(
          I18n.t("shop.transport_cost_changed"),
          I18n.t("admin.info"),
          {
            titleClass: "clickable-move-to-other",
            messageClass: "clickable-move-to-other",
          }
        );
      }
    }
  }, [cost, costVat]);

  const handleBlur = (onChange: (...event: any[]) => void, region: boolean) => {
    const label = region
      ? inputValueRegion
        ? inputValueRegion.trim()
        : ""
      : inputValue
      ? inputValue.trim()
      : "";
    const optionExists = countries.find((opt) => opt.label === label);

    if (!label || optionExists) {
      return;
    }

    const option = { label, value: label };
    // Add new option to menu list
    if (region) {
      setRegions([...regions, option]);
      handleRegionChange(option);
    } else {
      setCountries([...countries, option]);
      handleCountryChange(option);
    }
    onChange(option.value);
  };

  const onInputChange = (
    textInput: string,
    { action }: InputActionMeta,
    onChange: (...event: any[]) => void,
    region: boolean
  ) => {
    if (action === "input-change" || action === "set-value") {
      region ? setInputValueRegion(textInput) : setInputValue(textInput);
    }
    if (action === "input-blur") {
      handleBlur(onChange, region);
    }
  };

  const getTransportCost = (region: string, country: string) => {
    setCostLoading(true);
    Axios.post<{ cost: string | null; vat: string | null; net: string | null }>(
      prefixURL("/api/transport_costs/cost"),
      {
        country: country,
        region: region,
        weight: props.weight,
        palette_count: props.paletteCount,
        length_dm: props.lengthDm,
      },
      {
        headers: {
          accept: "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data) {
          if (res.data.cost !== null && res.data.vat) {
            setCost(
              `${parseFloat(res.data.cost).toFixed(2)} ${props.currency}`
            );
            setCostVat(parseFloat(res.data.vat).toFixed(2));
          } else {
            setCost(I18n.t("shop.transport_cost_individual"));
            setCostVat(I18n.t("shop.transport_cost_individual"));
          }
        } else {
          setCost(I18n.t("shop.transport_cost_individual"));
          setCostVat(I18n.t("shop.transport_cost_individual"));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setCostLoading(false);
      });
  };

  const getCountries = () => {
    Axios.get<string[]>(prefixURL("/api/transport_costs/countries"), {
      headers: {
        accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data) {
          const options = res.data.map((s) => {
            return { label: s, value: s };
          });
          setCountries(options);
          if (options.length === 1) {
            handleCountryChange(options[0]);
            props.setValue("transport_cost_country", options[0].value);
            // field.onChange(options[0].value);
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRegions = (country: string) => {
    Axios.get<string[]>(
      prefixURL(`/api/transport_costs/regions?country=${country}`),
      {
        headers: {
          accept: "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data) {
          const options = res.data.map((s) => {
            return { label: s, value: s };
          });
          setRegions(options);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!countries.length) {
    return <h5></h5>;
  }

  if (
    (customShipping === "1" && props.where === "billing") ||
    (customShipping === "0" && props.where === "shipping")
  ) {
    return <></>;
  }

  const disabledCountry =
    isCountryLoading || (props.directSellingDomain && countries.length === 1);

  return (
    <div className="row">
      <Controller
        control={props.control}
        name="transport_cost_country"
        defaultValue=""
        render={({ field }) => {
          return (
            <>
              <input
                required
                type="hidden"
                value={field.value ? field.value : ""}
                name="order[transport_cost_country]"
                id="order_transport_cost_country"
              />
              {props.directSellingDomain ? (
                <Select
                  className="col-12 col-sm-6 col-md-6 col-lg-6 my-1 p-0 pr-sm-3"
                  isClearable={false}
                  isDisabled={disabledCountry}
                  isLoading={isCountryLoading}
                  placeholder={`${I18n.t("shop.transport_country")} *`}
                  options={countries}
                  defaultValue={null}
                  value={
                    field.value
                      ? { label: field.value, value: field.value }
                      : null
                  }
                  onChange={(e: ISelectOption) => {
                    handleCountryChange(e);
                    field.onChange(e.value);
                  }}
                  inputValue={inputValue}
                  onInputChange={(
                    newValue: string,
                    actionMeta: InputActionMeta
                  ) =>
                    onInputChange(newValue, actionMeta, field.onChange, false)
                  }
                  tabSelectsValue={false}
                  onBlurResetsInput={false}
                  noOptionsMessage={() =>
                    I18n.t("shop.transport_country_empty")
                  }
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  formatCreateLabel={(text) => `${I18n.t("shop.add")} ${text}`}
                />
              ) : (
                <CreatableSelect
                  className="col-12 col-sm-6 col-md-6 col-lg-6 my-1 p-0 pr-sm-3"
                  isClearable={false}
                  isDisabled={disabledCountry}
                  isLoading={isCountryLoading}
                  placeholder={`${I18n.t("shop.transport_country")} *`}
                  options={countries}
                  defaultValue={null}
                  value={
                    field.value
                      ? { label: field.value, value: field.value }
                      : null
                  }
                  onChange={(e: ISelectOption) => {
                    handleCountryChange(e);
                    field.onChange(e.value);
                  }}
                  inputValue={inputValue}
                  onInputChange={(
                    newValue: string,
                    actionMeta: InputActionMeta
                  ) =>
                    onInputChange(newValue, actionMeta, field.onChange, false)
                  }
                  tabSelectsValue={false}
                  onBlurResetsInput={false}
                  noOptionsMessage={() =>
                    I18n.t("shop.transport_country_empty")
                  }
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  formatCreateLabel={(text) => `${I18n.t("shop.add")} ${text}`}
                />
              )}
            </>
          );
        }}
      />
      <Controller
        control={props.control}
        name="transport_cost_region"
        defaultValue=""
        render={({ field }) => {
          return (
            <>
              <input
                required
                type="hidden"
                value={field.value ? field.value : ""}
                name="order[transport_cost_region]"
                id="order_transport_cost_region"
              />
              <CreatableSelect
                className="col-12 col-sm-6 col-md-6 col-lg-6 my-1 p-0 pl-sm-3"
                isClearable={false}
                isDisabled={isRegionsLoading}
                isLoading={isRegionsLoading}
                placeholder={`${I18n.t("shop.transport_region")} *`}
                options={regions}
                defaultValue={null}
                value={
                  field.value
                    ? { label: field.value, value: field.value }
                    : null
                }
                onChange={(e: ISelectOption) => {
                  handleRegionChange(e);
                  field.onChange(e.value);
                }}
                inputValue={inputValueRegion}
                onInputChange={(
                  newValue: string,
                  actionMeta: InputActionMeta
                ) => onInputChange(newValue, actionMeta, field.onChange, true)}
                tabSelectsValue={false}
                onBlurResetsInput={false}
                noOptionsMessage={() => I18n.t("shop.transport_region_empty")}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                formatCreateLabel={(text) => `${I18n.t("shop.add")} ${text}`}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default SelectCountryRegion;
