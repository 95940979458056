import { createSlice } from "@reduxjs/toolkit";
import { ReverseGeocodingResponse } from "./CatalogRedirectModal";

interface initialStateType {
  showModal: boolean;
  selectedLocation: ReverseGeocodingResponse | undefined;
}

const initialState: initialStateType = {
  showModal: false,
  selectedLocation: undefined
};

export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setShowModal: (state, action: {payload: boolean, type: string}) => {
      state.showModal = action.payload;
    },
    setSelectedLocation: (state, action: { payload: ReverseGeocodingResponse, type: string}) => {
      state.selectedLocation = action.payload
    }
  },
});

export const { setShowModal, setSelectedLocation } = catalogSlice.actions;

export const selectShowModal = (state): boolean[] => state.catalog.showModal;

export const selectSelectedLocation = (state): ReverseGeocodingResponse | null => state.catalog.selectedLocation;

export default catalogSlice.reducer;
