import React from "react";
import { store } from "./store";
import { Provider } from "react-redux";
import Price from "./Price";
import SelectCountryRegion, {
  SelectCountryRegionProps,
} from "./SelectCountryRegion";
import { TransactionAdressesProps, TransactionAdressProps } from "./checkout/TransactionForm";
import { Control, FieldErrorsImpl } from "react-hook-form";

export interface TransactionTransportComponentProps extends SelectCountryRegionProps {
  type: "price" | "select";
  price: number;
  vatPrice: number;
  showTransport: boolean;
  vatValue: number;
  where: "billing" | "shipping";
  transport_cost_from_server?: number;
  transport_cost_vat_from_server?: number;
  isPayment: boolean;
  control: Control<TransactionAdressProps, any>;
  errors: Partial<FieldErrorsImpl<TransactionAdressProps>>;
}

const TransactionTransportComponent = (
  props: TransactionTransportComponentProps
) => {
  return (
    <>
      <Provider store={store}>
        {props.type === "price" ? (
          <Price {...props} />
        ) : (
          <SelectCountryRegion {...props} />
        )}
      </Provider>
    </>
  );
};

export default TransactionTransportComponent;
