import React, { useState } from "react";
import { ProductShop } from "../../../actions/types";
interface ProductProps {
  product: ProductShop;
  isCatalog: boolean;
  isB2B: boolean;
}

declare function prefixURL(url: string): string;

const Product = (props: ProductProps) => {
  const [imgSrc, setImgSrc] = useState(props.product.img);
  return (
    <div
      className="col-md-6 col-lg-4 pb-5 mb-lg-0"
      style={{ backgroundColor: "white" }}
    >
      <div className="product-list__item">
        <div className="product-list">
          <div className="product-list__inner">
            <div>
              <a
                href={prefixURL(props.product.url)}
                className="product-list__link"
              >
                <span
                  className="product-list__badge "
                  style={{ textAlign: "center" }}
                >
                  {props.product.category_names}
                </span>
                <img
                  className="card-img-top"
                  src={imgSrc}
                  alt="Not Found"
                  onError={(e) =>
                    setImgSrc(prefixURL("/assets/shop/photo-placeholder.jpg"))
                  }
                  style={{ width: "100%" }}
                />
              </a>
              <p className="product-list__title">
                <a href={prefixURL(props.product.url)}>{props.product.name}</a>
              </p>
              <p className="product-list__info">
                {I18n.t("shop.product_no")}: {props.product.id}
              </p>
              <div className="dropdown show">
                <strong>
                  {!props.isCatalog && !props.isB2B && (
                    <p
                      style={{ border: "1px solid #d4dbe3" }}
                      className="product-list__price"
                    >
                      {`${props.product.product_price} ${props.product.currency_symbol}`}
                    </p>
                  )}
                </strong>

                <a
                  href={prefixURL(props.product.url)}
                  className="btn btn--blue"
                >
                  {I18n.t("shop.project_details")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
