import React from "react";
import { store } from "../products/store";
import { Provider } from "react-redux";
import FloatingIcon from "./FloatingIcon";

export interface CatalogAppProps {
  catalogsInfos: CatalogInfo[];
}

interface CatalogInfo {
  longitute: string;
  latitude: string;
  url: string;
  country: string;
}

const CatalogApp = (props: CatalogAppProps) => {
  return (
    <Provider store={store}>
      <FloatingIcon {...props}/>
    </Provider>
  );
};

export default CatalogApp;
