import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import CookiesEuBanner from 'cookies-eu-banner'
import { isMobile } from 'react-device-detect'

function CookiesConsent({ articleUrl, translatedText }) {
  const [isAccepted, setIsAccepted] = useState(false),
    styles = {
      container: {
        display: isAccepted ? 'none' : 'block',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: 'auto',
        backgroundColor: '#446084',
        fontFamily: ['Poppins', 'sans-serif'],
        fontSize: 10,
        color: '#fff',
        zIndex: 5,
        textAlign: 'center',
      }
    },
    acceptCookies = () => {
      setIsAccepted(true)
      Cookies.set('consent', true)
    };

  useEffect(() => {
    new CookiesEuBanner(() => {
      let accepted = Cookies.get('consent')
      if (accepted) setIsAccepted(true)
    })
  }, [])

  return (
    <div
      id="cookies-eu-banner"
      style={styles.container}
      className="pt-2 pb-2"
    >
      {!isMobile &&
        <p className="mr-5 text-white d-inline">{translatedText.message}</p>
      }

      <button
        id="cookies-eu-accept"
        className="btn btn-sm mr-3"
        onClick={() => acceptCookies()}
      >
        {isMobile ? translatedText.acceptCookies : translatedText.accept}
      </button>

      {articleUrl ? (<a href={articleUrl} id="cookies-eu-more" target="_blank">{translatedText.readMore}</a>) : null}
    </div>
  )
}

export default CookiesConsent
