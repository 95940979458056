export const SET_USER_ROLES = 'SET_USER_ROLES'
export const SET_ORDER = 'SET_ORDER'
export const ADD_ADDITION_LINE = 'ADD_ADDITION_LINE'
export const ADD_PROJECT_LINE = 'ADD_PROJECT_LINE'
export const ADD_PRODUCT_LINE = 'ADD_PRODUCT_LINE'
export const PRODUCT_LINE_CHANGED = 'PRODUCT_LINE_CHANGED'
export const PROJECT_LINE_CHANGED = 'PROJECT_LINE_CHANGED'
export const ADDITION_LINE_CHANGED = 'ADDITION_LINE_CHANGED'
export const REMOVE_ADDITION_LINE = 'REMOVE_ADDITION_LINE'
export const REMOVE_PROJECT_LINE = 'REMOVE_PROJECT_LINE'
export const REMOVE_PRODUCT_LINE = 'REMOVE_PRODUCT_LINE'
export const CLEAR_STORE = 'CLEAR_STORE'
export const SET_PROJECTS = 'SET_PROJECTS'

export type int = number;

declare global {
  namespace I18n {
    function t(s: string): string;
  }
}

export interface ISelectOption {
  label: string,
  value: string
}

export interface SubscriberListData {
  id: int;
  name: string;
  description: string;
  sendername: string;
  senderemail: string;
  domain: string;
  subscribers_count: int;
  created_at: string;
  updated_at: string;
}

export interface StandardProjectData {
  id: number;
  name: string;
  img_url: null;
  url: string;
  price: number;
  quantity: number;
  vat_tax: number;
  price_vat: number;
  price_net: number;
  show_vat: boolean;
  show_transport: boolean;
  additions: AdditionData[];
}

export interface AdditionData {
  id: number;
  price: number;
  code: string;
  name: string;
  addition_id: number;
  label: string;
  option_id: null;
  quantity: number;
  quantity_unit: string;
  checkbox: boolean;
  options: AdditionOptionData[];
}

export interface AdditionOptionData {
  id: number;
  price: number;
  montage_price:number;
  montage_price_whs:number;
  code?:string;
  price_vat: number;
  price_net: number;
  vat_tax: number;
  is_zero: boolean;
  label: string;
  checked: boolean;
  addition_option_id: number | null;
  img_url: null | string;
  weight: number;
  palette_count: number;
  length_dm: number;
}


export interface SelectOptionData {
  value: string,
  label: string,
}

export interface AvailAdditionOptionData {
  code: string,
  value: string,
  label: string,
  price: number,
  price_net: number,
  price_vat: number,
  vat_tax: number,
  whole_sale_price: number,
  montage_price: number,
  montage_price_whs: number,
}

export interface AvailAdditionData {
  value: number,
  label: string,
  options: AvailAdditionOptionData[]
}

export interface AvailableAdditions {
  available: AvailAdditionData[],
  onlyAdditions: SelectOptionData[]
}

export interface CalcLineData {
  quantity?: number,
  price?: number,
  unit_price_exc_vat: number,
  unit_price_exc_vat_orig: number,
  vat_tax?: int,
  price_net?: number,
  price_vat?: number,
  unit_price: number,
  whole_sale_price_unit: number,
  whole_sale_price?: number,
  whole_sale_price_orig: number,
}

export interface AdditionLineData extends CalcLineData {
  id?: number,
  pm_id?: number,
  label?: string,
  supply?: boolean,
  code: string,
  unit_montage_price?: number,
  unit_montage_price_whs?: number,
  project_addition_id?: number,
  project_addition_option_id?: number,
  addition_lines?: AdditionData[],
  unidentified?: boolean,
  destroy?: number,
}

export type AdditionLineData_Subset = Pick<AdditionLineData, 'whole_sale_price' | 'code' | 'destroy' | 'label' | 'supply'>;

export interface ProjectData {
  id: number,
  name: string,
  is_standard: boolean,
  whole_sale_price: number,
  body: string
}

export interface ProductData {
  id: number,
  name: string,
  whole_sale_price: number,
  kind?: string,
}

export interface DomainChoiceData {
  id: number,
  domain_name: string,
  active: boolean,
  is_selected: boolean,
}

export interface OrderData {
  id: number,
  parent_id: number,
  position: int,
  status: string,
  vis_status: string,
  vat_tax: number,
  vat?: number,
  project_names?: string,
  project_lines: ProjectLineData[],
  product_lines: ProductLineData[],
  total_price: number,
  domain: string,
  ref_no: string,
  confirmed_at: string,
  created_at: string,
  updated_at: string,
  customer: UserData,
  deadline: number,
  confirm_action: boolean,
  indesign_action: boolean,
  subRows?: OrderData[]
}

export interface PriceChangeData {
  amount: number,
  message: string
}

export interface ProjectLineData extends CalcLineData {
  id?: number,
  pm_id?: number,
  smart_id?:string,
  addition_lines: AdditionLineData[],
  project_id?: number,
  project_name?: string,
  project?: ProjectData,
  price_change?: PriceChangeData,
  attachment?: any,
  status: string,
  destroy?: number
}


export type ProjectLineData_Subset = Pick<ProjectLineData,  'destroy' >;

export interface ProductVariantData {
  id: number,
  whole_sale_price: number,
  label: string
}

export interface ProductLineData {
  id?: number,
  unit_price?: number,
  quantity?: number,
  price?: number,
  unit_price_exc_vat?: number,
  unit_price_exc_vat_orig?: number,
  vat_tax?: int,
  price_net?: number,
  price_vat?: number,
  name?: string,
  whole_sale_price?: number,
  whole_sale_price_orig: number,
  whole_sale_price_unit: number,
  product_name?: string,
  product_id?: number,
  product?: ProductData,
  product_variant_id?: number,
  product_variant?: ProductVariantData,
  unidentified?: boolean,
  destroy?: number
}

export type ProductLineData_Subset = Pick<ProductLineData,  'destroy' | 'name' >;


export interface UserData {
  id: number,
  email: string,
  last_name: string,
  first_name: string,
  phone: string,
  domain?: string,
  name: string,
  avatar: { url: string },
  has_role_architect: boolean,
  can_upload_visualization?: boolean
}

export interface AttachmentData {
  id: number,
  name: string,
  type: string,
  content_type: string,
  file_name: string,
  file_type: string,
  description: string,
  comment: string,
  created_at: string,
  file: { url: string },
  visualization_approved_at: string,
  visualization_approved_by: string,
  visualization: boolean,
  approved: boolean
}

export interface DocumentData {
  id: number,
  category_id: number,
  title: string,
  description: string,
  file: { url: string },
  file_name: string,
  file_type: string,
  file_extension: string,
  visible: boolean,
  for_logged: boolean,
  created_at: string,
  publish_at: string,
  newsletter_id: number,
  domain_id: number,
  article_id: number
}

export interface NotificationData {
  id: number,
  order_id: number,
  order?: OrderData,
  subject: string,
  message: string,
  created_at: string,
  read_at: string,
  sent_at: string,
  confirmed_at: string,
  action_required: boolean,
  action_done_at: string,
  icon: string,
  kind: string,
  immediate_reply: boolean,
  originator: UserData,
  recipient: UserData,
  attachments: AttachmentData[],
  child?: NotificationData
}

export interface IFullProject {
  id: number;
  name: string;
  body: string;
  domain_id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
  is_template: boolean;
  preview: string;
  author: string;
  old_project_id: null;
  price: string;
  area: string;
  is_public: boolean;
  uuid: string;
  price_exc_vat: string;
  whole_sale_price: string;
  whole_sale_price_in_euro: string;
  costprice_in_euro: string;
  local_factor: string;
  local_vat: string;
  local_final_factor: string;
  montage: null;
  exchange_rate: null;
  currency: string;
  final_factor: null;
  locked: boolean;
  author_of_changes: string;
  ground_surface: string;
  attic_surface: string;
  external_cabin_width: string;
  external_cabin_height: string;
  internal_cabin_width: string;
  internal_cabin_height: string;
  catalog_picture: CatalogPicture;
  related_projects_ids: any[];
  slug: string;
  url: string;
  resource_id: null;
  ridge_height: string;
  roof_area: string;
  quantity_of_doors: null;
  quantity_of_windows: null;
  roof_overhang: string;
  base_footprint: string;
  windows_type: null;
  doors_size: null;
  doors_type: null;
  glazing: null;
  width_x_depth: string;
  windows_size: null;
  shutters: boolean;
  quantity_of_rooms: number;
  warranty: number;
  in_site_map: boolean;
  windows_list: string;
  doors_list: string;
  door_lock: string;
  walls_thickness: number;
  house_comps: string;
  price_debug: string;
  is_standard: boolean;
  old2_project_id: null;
  smart_id: string;
  parent_id: null;
  side_wall_height: string;
  external_wall_surface: string;
  floor_boards_thickness: string;
  terraces_surface: null;
  balconies_surface: null;
  roof_shingles: string;
  ext_wall_area_insul: string;
  ext_wall_area_paint: string;
  roof_angle: null;
  reverse_montage: boolean;
  roof_boards: number;
  internal_area: string;
  underlayer: string;
  ridge_length: null;
  roof_style: number;
  domain_constrained: boolean;
  quality: string;
  glazing_type: string;
  weight: number | null;
  palette_count: number | null;
  length_dm: number | null;
  description: string;
  short_description: null;
  meta_tags: string;
  meta_description: string;
}

interface CatalogPicture {
  url: null;
  thumb: Thumb;
}

interface Thumb {
  url: null;
}

export interface ProductsShop {
  filters: Filters;
  products: ProductShop[];
  settings: ShopSettings;
  pagy: Pagy;
}

export interface Filters {
  price_range: number[];
  width_range: string[];
  height_range: string[];
  by_categories: Array<[number, number, string, boolean]>;
  by_number_of_rooms: Array<[number, number, boolean]>;
  by_area: Array<Array<boolean | ByAreaClass>>;
  by_thickness: Array<[string, number, boolean]>;
  by_quality: Array<[number, number, boolean]>;
  start_ranges: StartRanges;
}

export interface ByAreaClass {
  value_pairs: Array<[string, number]>;
}

export interface StartRanges {
  price: number[];
  width: string[];
  height: string[];
}

export interface ProductShop {
  id: number;
  category_names: string;
  img: string;
  url: string;
  name: string;
  product_price: string;
  currency_symbol: string;
}

export interface Pagy {
  overflow: boolean;
  vars: PagyVars;
  count: number;
  items: number;
  outset: number;
  page: number;
  last: number;
  pages: number;
  offset: number;
  from: number;
  to: number;
  prev: number | null;
  next: number | null;
  series: Array<number | string>;
}

export interface ShopSettings {
  page: number;
  items: string;
  sort_by: string | null;
  q: {
    name_i_cont?: string;
    price_gteq?: string;
    price_lteq?: string;
    quantity_of_rooms_eq: string[];
    ground_surface_gteq: string[];
    walls_thickness_eq: string[]
    quality_eq: string[];
    by_area: string[],
    external_cabin_width_gteq: string[];
    external_cabin_width_lteq: string[];
    external_cabin_height_gteq: string[];
    external_cabin_height_lteq: string[];
  }
}

interface PagyVars {
  page: number;
  items: number;
  outset: number;
  size: number[];
  page_param: string;
  params: PagyParams;
  anchor: string;
  link_extra: string;
  i18n_key: string;
  cycle: boolean;
  overflow: string;
  count: number;
}

interface PagyParams { }

export const defaultShopPagy = {
  overflow: false,
  vars: {
    page: 1,
    items: 9,
    outset: 0,
    size: [1, 4, 4, 1],
    page_param: "page",
    params: {},
    anchor: "",
    link_extra: 'class="page-link" ',
    i18n_key: "pagy.item_name",
    cycle: false,
    overflow: "empty_page",
    count: 149,
  },
  count: 149,
  items: 9,
  outset: 0,
  page: 1,
  last: 17,
  pages: 17,
  offset: 0,
  from: 1,
  to: 9,
  prev: null,
  next: 2,
  series: ["1", 2, 3, 4, 5, "gap", 17],
};

export interface CurrentUser {
  email: string;
  first_name: null | string;
  last_name: null | string;
  company: null | string;
  phone: null | string;
  address_1: null | string;
  address_2: null | string;
  address_3: null | string;
  country: null | string;
  city: null | string;
  zip_code: null | string;
  state: null | string;
  guest: boolean;
}
