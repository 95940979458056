import { createSlice } from "@reduxjs/toolkit";

export interface CheckboxCustomShippingState {
  customShipping: "0" | "1";
}

const initialState: CheckboxCustomShippingState = {
  customShipping: "0"
};

export const checkboxSlice = createSlice({
  name: "checkbox",
  initialState,
  reducers: {
    setCustomShipping: (state, action: { payload: "0" | "1"; type: string }) => {
      state.customShipping = action.payload;
    },
  },
});

export const { setCustomShipping } = checkboxSlice.actions;

export const selectCustomShipping = (state: {customShipping: CheckboxCustomShippingState}) => {
  return state.customShipping.customShipping;
}

export default checkboxSlice.reducer;
