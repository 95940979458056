import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceWithCurrency } from "../../../actions/functions";
import {
  AdditionOptionData,
  IFullProject,
  StandardProjectData,
} from "../../../actions/types";
import Upsale from "../additions/Upsale";
import { setShowModal } from "../catalog/catalogSlice";
import TransportCost from "../transport/TransportCost";
import { selectOptions } from "./productSlice";

declare function prefixURL(url: string): string;

export interface RightPanelProps {
  directSellingDomain: boolean;
  isCatalog: boolean;
  product: IFullProject;
  sibling_product: IFullProject;
  price_diff: number;
  currencySymbol: string;
  url: string;
  addUrl: string;
  plannerAllowed: boolean;
  plannerUrl: string;
}

export interface TransportCostParams {
  weight: number | null;
  palette_count: number | null;
  length_dm: number | null;
}

declare function prefixURL(url: string): string;

// @ts-ignore
const csrf = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

const RightPanel = (props: RightPanelProps) => {
  const dispatch = useDispatch();
  const [projectPrice, setProjectPrice] = useState<number>(0),
    [basicProjectPrice, setBasicProjectPrice] = useState<number>(0),
    [projectQty, setProjectQty] = useState<number>(1),
    [additionsSum, setAdditionsSum] = useState<number>(0),
    [additionsVatSum, setAdditionsVatSum] = useState<number>(0),
    [montageSum, setMontageSum] = useState<number>(0),
    [vatTax, setVatTax] = useState(0),
    [vat, setVat] = useState(0),
    [showVat, setShowVat] = useState(false),
    [showTransport, setShowTransport] = useState(false),
    [transportParams, setTransportParams] = useState<TransportCostParams>({
      weight: props.product.weight,
      palette_count: props.product.palette_count,
      length_dm: props.product.length_dm,
    }),
    [additionsTransportParams, setAdditionsTransportParams] =
      useState<TransportCostParams>({
        weight: null,
        palette_count: null,
        length_dm: null,
      }),
    selectedOptions: AdditionOptionData[] = useSelector(selectOptions),
    clickPlusMinusQty = (type: "plus" | "minus") => {
      if (type === "minus" && projectQty - 1 <= 0) {
        return;
      }
      if (type === "plus") {
        setProjectQty((qty) => qty + 1);
      } else {
        setProjectQty((qty) => qty - 1);
      }
    },
    setIsOpen = (state: boolean) => {
      dispatch(setShowModal(state));
    };

  useEffect(() => {
    Axios.get<StandardProjectData>(props.url, {
      headers: {
        accept: "application/json",
        "X-CSRF-Token": csrf,
      },
    })
      .then((res) => {
        setBasicProjectPrice(Number(res.data.price));
        setVatTax(Number(res.data.vat_tax));
        setVat(Number(res.data.price_vat));
        setShowVat(res.data.show_vat);
        setShowTransport(res.data.show_transport);
      })
      .catch((err) => console.log("ERR: ", err));
  }, []);

  useEffect(() => {
    const newPrice = (basicProjectPrice + additionsSum) * projectQty;
    setProjectPrice(newPrice);
  }, [basicProjectPrice, projectQty, additionsSum]);

  useEffect(() => {
    const montage = selectedOptions.find((o) => o.code === "montage");

    const sumOfMontage = montage
      ? selectedOptions
          .filter((opt) => opt.code !== "montage")
          .reduce((prev, curr) => prev + Number(curr.montage_price), 0)
      : 0;

    setMontageSum(sumOfMontage);

    const sumOfAdditions = selectedOptions.reduce((prev, curr) => {
      return prev + Number(curr.price);
    }, 0);

    const sumOfAdditionsVat = selectedOptions.reduce((prev, curr) => {
      return prev + Number(curr.price_vat);
    }, 0);

    if (montage && !montage.is_zero) {
      const sumOfMontageVat =
        sumOfMontage - sumOfMontage / (1 + 0.01 * montage.vat_tax);
      setAdditionsSum(sumOfAdditions + sumOfMontage);
      setAdditionsVatSum(sumOfAdditionsVat + sumOfMontageVat);
    } else {
      setAdditionsSum(sumOfAdditions);
      setAdditionsVatSum(sumOfAdditionsVat);
    }

    const weight = selectedOptions.reduce((prev, curr) => {
      return prev + curr.weight;
    }, 0);
    const palette_count = selectedOptions.reduce((prev, curr) => {
      return prev + curr.palette_count;
    }, 0);
    const length_dm = selectedOptions.reduce((prev, curr) => {
      return prev + curr.length_dm;
    }, 0);
    setAdditionsTransportParams({
      weight,
      palette_count,
      length_dm,
    });
  }, [selectedOptions]);

  useEffect(() => {
    const _weight =
      additionsTransportParams.weight === null || props.product.weight === null
        ? null
        : (additionsTransportParams.weight + props.product.weight) * projectQty;
    const _length_dm =
      additionsTransportParams.length_dm === null ||
      props.product.length_dm === null
        ? null
        : (additionsTransportParams.length_dm + props.product.length_dm) *
          projectQty;
    const _palette_count =
      additionsTransportParams.palette_count === null ||
      props.product.palette_count === null
        ? null
        : (additionsTransportParams.palette_count +
            props.product.palette_count) *
          projectQty;
    setTransportParams({
      weight: _weight,
      length_dm: _length_dm,
      palette_count: _palette_count,
    });
  }, [projectQty, additionsTransportParams]);

  const showVatPrice = !!showVat;
  if (props.isCatalog) {
    return (
      <>
        <div className="product__sidebar">
          <h1>{props.product.name}</h1>
          <ul className="product__sidebar-list">
            <li>
              {I18n.t("shop.product_no")}: {props.product.id}{" "}
            </li>
          </ul>
          <input
            type="button"
            value={I18n.t("shop.check_the_price")}
            className="btn btn--ultrablue btn-block text-center"
            onClick={() => setIsOpen(true)}
          />
        </div>
        {/* <% end %> */}
      </>
    );
  } else {
    return (
      <>
        <div className="product__sidebar">
          <div id="floating_price" className="d-none">
            <div className="">
              <span className="">{I18n.t("shop.cabin_price")}: </span>
              <span className="">
                {priceWithCurrency(projectPrice, props.currencySymbol)}
              </span>
            </div>
          </div>
          <h1>{props.product.name}</h1>
          <div className="product__price-row">
            <span className="product__price-label">
              {I18n.t("shop.cabin_price")}
            </span>
            <span className="product__price">
              {priceWithCurrency(projectPrice, props.currencySymbol)}
            </span>
          </div>
          {showVatPrice && (
            <div className="product__vat-row">
              <span className="product__vat-label">
                ({I18n.t("shop.include_vat")} {I18n.t("shop.vat")})
              </span>
              <span className="product__vat">
                {priceWithCurrency(
                  projectQty * (vat + additionsVatSum),
                  props.currencySymbol
                )}
              </span>
            </div>
          )}
          {showTransport && (
            <div className="product__transport-cost-row">
              <span className="product__transport-cost-label">
                {I18n.t("shop.transport_cost")}:
              </span>
              <span className="product__transport-cost">
                <TransportCost
                  directSellingDomain={props.directSellingDomain}
                  transportParams={transportParams}
                  currency={props.currencySymbol}
                />
              </span>
            </div>
          )}
          {/* <%= form_with(model: @product, url: add_to_cart_path(@product.id), method: :post, local: true) do |form| %> */}
          <form
            action={prefixURL(props.addUrl)}
            acceptCharset="UTF-8"
            method="post"
          >
            <input type="hidden" name="authenticity_token" value={csrf || ""} />
            <input type="hidden" value={props.product.id} name="product_id" />
            <div className="product__controls-row d-flex flex-row-reverse">
              <div className="cart__qty-col">
                <div className="form-group">
                  <div className="quantity quantity-lg">
                    <input
                      type="button"
                      value="-"
                      className="minus btn"
                      onClick={() => clickPlusMinusQty("minus")}
                    />
                    <input
                      value={projectQty}
                      onChange={(e) => setProjectQty(+e.target.value)}
                      name="project[quantity]"
                      type="number"
                      min="1"
                      className="form-control cart__qty-input"
                      title={I18n.t("shop.quantity")}
                    />
                    <input
                      type="button"
                      value="+"
                      className="plus btn"
                      onClick={() => clickPlusMinusQty("plus")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <ul className="product__sidebar-list">
              <li>
                {I18n.t("shop.product_no")}: {props.product.id}{" "}
              </li>
            </ul>

            {props.sibling_product && (
              <div
                style={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: 12,
                  marginBottom: 15,
                }}
              >
                <a href={prefixURL(`/products/${props.sibling_product.slug}`)}>
                  {" "}
                  {props.sibling_product.quality == "premium"
                    ? I18n.t("shop.upgrade_to_premium")
                    : I18n.t("shop.downgrade_to_economy")}{" "}
                </a>
                <span style={{ color: "lightgray", marginLeft: 5 }}>
                  ({props.price_diff > 0 && "+"}{" "}
                  {priceWithCurrency(props.price_diff, props.currencySymbol)})
                </span>
              </div>
            )}

            <div className="product__sidebar-btns">
              <input
                type="submit"
                name="commit"
                value={I18n.t("shop.buy")}
                data-disable-with={I18n.t("shop.buy")}
                className="btn btn--ultrablue btn-block text-center"
              />
              {!!props.product.body && props.plannerAllowed && (
                <a className="btn btn--blue" rel="nofollow" href={prefixURL(props.plannerUrl)}>
                  <img src={prefixURL("/assets/shop/ruler.png")} alt="" />
                  {I18n.t("shop.customize")}
                </a>
              )}
            </div>
            <Upsale
              isCatalog={props.isCatalog}
              qty={projectQty}
              project_id={props.product.id}
              currencySymbol={props.currencySymbol}
              showVat={showVat}
              montageSum={montageSum}
            />
          </form>
        </div>
        {/* <% end %> */}
      </>
    );
  }
};

export default RightPanel;
