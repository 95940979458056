import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { defaultShopPagy, ProductShop } from "../../../actions/types";
import productsReducer from "./productsSlice";

export const store = configureStore({
  reducer: {
    products: productsReducer,
    // filters: filtersReducer
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

function logger({ getState }) {
  return (next) => (action) => {
    console.log("====================BEGIN=====================");
    console.log("will dispatch", action);

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    console.log("state after dispatch", getState());
    console.log("=====================END======================");
    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}
