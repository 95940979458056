import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSlider from "react-slider";
import styled from "styled-components";
import { Filters } from "../../../actions/types";
import {
  getProductsFromApi,
  selectProductsSettings,
  setSettings,
} from "../products_list/productsSlice";

const StyledTrack = styled.div<{ index: number }>`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 2 ? "#D6D7D9" : props.index === 1 ? "#345DBB" : "#D6D7D9"};
  border-radius: 999px;
`;

const StyledThumb = styled.div<{ index: number }>`
  height: 18px;
  line-height: 18px;
  width: 18px;
  top: -7px;
  text-align: center;
  background-color: #345dbb;
  color: #345dbb;
  border-radius: 50%;
  cursor: grab;
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 4px;
`;

interface SliderProps {
  filters: Filters | null;
  type: "price" | "width" | "length";
  currencySymbol?: string;
}

const Slider = (props: SliderProps) => {
  const dispatch = useDispatch();
  const productsSettings = useSelector(selectProductsSettings);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [firstValue, setFirstValue] = useState(0);
  const [secondValue, setSecondValue] = useState(0);
  const [value, setValue] = useState([0, 0]);

  const updateValue = (newValue: number | readonly number[], index: number) => {
    let valueCopy = [...value];
    valueCopy[index] =
      typeof newValue === "number" ? newValue : newValue[index];
    setValue(valueCopy);
  };

  const Thumb = (props, state) => {
    return <StyledThumb {...props}></StyledThumb>;
  };

  const Track = (props, state) => {
    return <StyledTrack {...props} index={state.index} />;
  };

  const step = () => {
    if (maxValue > 10000) {
      return 100;
    }
    if (maxValue > 1000) {
      return 10;
    }
    if (maxValue > 100) {
      return 1;
    }
    return 0.1;
  };

  useEffect(() => {
    if (props.filters) {
      if (props.type === "price") {
        setMinValue(props.filters.price_range[0]);
        setMaxValue(props.filters.price_range[1]);
        setFirstValue(props.filters.price_range[0]);
        setSecondValue(props.filters.price_range[1]);
        setValue([
          props.filters.start_ranges.price[0] || props.filters.price_range[0],
          props.filters.start_ranges.price[1] || props.filters.price_range[1],
        ]);
      } else if (props.type === "width") {
        setMinValue(+props.filters.width_range[0]);
        setMaxValue(+props.filters.width_range[1]);
        setFirstValue(+props.filters.width_range[0]);
        setSecondValue(+props.filters.width_range[1]);
        setValue([
          +props.filters.start_ranges.width[0] || +props.filters.width_range[0],
          +props.filters.start_ranges.width[1] || +props.filters.width_range[1],
        ]);
      } else if (props.type === "length") {
        setMinValue(+props.filters.height_range[0]);
        setMaxValue(+props.filters.height_range[1]);
        setFirstValue(+props.filters.height_range[0]);
        setSecondValue(+props.filters.height_range[1]);
        setValue([
          +props.filters.start_ranges.height[0] ||
            +props.filters.height_range[0],
          +props.filters.start_ranges.height[1] ||
            +props.filters.height_range[1],
        ]);
      }
    }
  }, [props.filters]);

  return (
    <div
      className="collapse show toggle-content"
      id={`toggle-content-${"field"}`}
    >
      <div className="row pt-3 justify-content-center">
        <div className="col-sm-12 p-0">
          <div className="react-slider">
            {" "}
            <StyledSlider
              max={maxValue}
              min={minValue}
              value={value}
              step={step()}
              onChange={(value, index) => updateValue(value, index)}
              onAfterChange={(value, index) => {
                const obj =
                  props.type === "price"
                    ? { price_gteq: value[0], price_lteq: value[1] }
                    : props.type === "length"
                    ? {
                        external_cabin_height_gteq: value[0],
                        external_cabin_height_lteq: value[1],
                      }
                    : props.type === "width"
                    ? {
                        external_cabin_width_gteq: value[0],
                        external_cabin_width_lteq: value[1],
                      }
                    : {};
                dispatch(
                  setSettings({
                    ...productsSettings,
                    q: {
                      ...productsSettings.q,
                      ...obj,
                    },
                    page: 1,
                  })
                );
                dispatch(getProductsFromApi(""));
              }}
              defaultValue={[firstValue, secondValue]}
              renderTrack={Track}
              renderThumb={Thumb}
            />
          </div>
        </div>
      </div>
      <div className="row slider-labels pt-3 justify-content-center">
        <div className="col-xs-6 caption">
          <strong>{props.currencySymbol}</strong>
          <span> {value[0].toFixed(0)}</span>
        </div>
        <div className="col-xs-6 text-right caption">
          &nbsp;- <span> {value[1].toFixed(0)}</span>
        </div>
      </div>
    </div>
  );
};
export default Slider;
