import React, { useEffect } from "react";
import { priceWithCurrency } from "../../../actions/functions";
import { AdditionData, AdditionOptionData } from "../../../actions/types";

declare namespace I18n {
  function t(s: string): string;
}

interface IUpsaleAdditionOption {
  additionOption: AdditionOptionData;
  addition: AdditionData;
  currencySymbol: string;
  isChecked: boolean;
  optionSelected: (addon: AdditionData, opt: AdditionOptionData) => void;
  index: number;
  qty: number;
  showVat: boolean;
  hasMontage: boolean;
  montageSum: number;
}

declare function prefixURL(url: string): string;
const UpsaleAdditionOption = (props: IUpsaleAdditionOption) => {
  const opt = props.additionOption,
    toggleChecked = () => {
      props.optionSelected(props.addition, props.additionOption);
    };

  const fieldName = `project[project_additions_attributes][${props.index}][option_id]`,
    fieldId = `${props.addition.code}-${opt.id}`;

  useEffect(() => {
    // @ts-ignore
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
    <div className="row mb-1">
      <div className={`col-9 pt-2 d-flex`}>
        <div className={`form-check align-self-center`}>
          <input
            type="radio"
            onChange={() => toggleChecked()}
            className="form-check-input"
            name={fieldName}
            id={fieldId}
            checked={props.isChecked}
            value={opt.id}
          />

          <label className={`form-check-label`} htmlFor={fieldId}>
            {opt.label}
            {!opt.is_zero && (
              <span>
                {" "}
                (+
                {priceWithCurrency(
                  (Number(opt.price) + props.montageSum) * props.qty,
                  props.currencySymbol
                )}
                )
                <i
                  className="fa fa-question-circle"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-html="true"
                  title={tooltipAdditionPrice(
                    opt.price,
                    opt.vat_tax,
                    opt.price_vat,
                    opt.price_net,
                    props.currencySymbol,
                    props.showVat
                  )}
                ></i>
              </span>
            )}
          </label>
        </div>
      </div>

      <div className="col-3 text-right">
        <img
          src={prefixURL(opt.img_url) || ""}
          className="img-fluid"
          style={{ maxHeight: 80 }}
        />
      </div>
    </div>
  );
};

const tooltipAdditionPrice = (
  price: number,
  vatTax: number,
  vat: number,
  net: number,
  currencySymbol: string,
  showVat: boolean
): string => {
  return `
  <span>${I18n.t("shop.gross")}: ${priceWithCurrency(
    price,
    currencySymbol
  )}</span>
  ${
    vatTax && showVat
      ? `
    <br>
    <span>${I18n.t("shop.include_vat")} ${vatTax}% ${I18n.t(
          "admin.vat_tax"
        )} ${priceWithCurrency(vat, currencySymbol)}</span>
    <br>
    <span>${I18n.t("shop.net")}: ${priceWithCurrency(
          net,
          currencySymbol
        )}</span>
    `
      : ""
  }
  `;
};

export default UpsaleAdditionOption;
