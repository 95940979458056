import React from "react";
import { Provider } from "react-redux";
import Products from "./Products";
import { store } from "./store";

interface ProductsListAppProps {
  isCatalog: boolean;
  isB2B: boolean;
}

const ProductsListApp = (props: ProductsListAppProps) => {
  return (
    <Provider store={store}>
      <Products isCatalog={props.isCatalog} isB2B={props.isB2B} />
    </Provider>
  );
};

export default ProductsListApp;
