import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagy, ShopSettings } from "../../../actions/types";
import {
  getProductsFromApi,
  selectProducts,
  selectProductsPagy,
  selectProductsSettings,
  setSettings,
} from "../products_list/productsSlice";

const Pagination = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const settings: ShopSettings = useSelector(selectProductsSettings);
  const pagy: Pagy = useSelector(selectProductsPagy);

  const clickPage = (page: number) => {
    dispatch(setSettings({ ...settings, page }));
    dispatch(getProductsFromApi(""));
  };

  useEffect(() => {

  }, [])

  const Previous = () => {
    return pagy.prev ? (
      <li className="page-item" style={{ cursor: "pointer" }}>
        <a onClick={() => clickPage(pagy.prev || 1)} className="page-link" rel="prev">
          <i className="fas fa-angle-left"></i>
        </a>
      </li>
    ) : (
      <li className="page-item disabled">
        <a className="page-link " style={{ cursor: "default" }}>
          <i className="fas fa-angle-left"></i>
        </a>
      </li>
    );
  };

  const Next = () => {
    return pagy.next ? (
      <li className="page-item" style={{ cursor: "pointer" }}>
        <a onClick={() => clickPage(pagy.next || 1)} className="page-link" rel="next">
          <i className="fas fa-angle-right"></i>
        </a>
      </li>
    ) : (
      <li className="page-item disabled">
        <a className="page-link " style={{ cursor: "default" }}>
          <i className="fas fa-angle-right"></i>
        </a>
      </li>
    );
  };

  const Middle = () => {
    return pagy.series.map((serie, i) => {
      return (
        <React.Fragment key={`serie-${serie}-idx-${i}`}>
          {serie === "gap" && (
            <li className="page-item">
              <a className="page-link" style={{ cursor: "default" }}>
                ...
              </a>
            </li>
          )}
          {typeof serie === "string" && serie !== "gap" && (
            <li className="page-item active" style={{ cursor: "default" }}>
              <a className="page-link" rel="prev">
                {serie}
              </a>
            </li>
          )}
          {typeof serie === "number" && (
            <li className="page-item" style={{ cursor: "pointer" }}>
              <a onClick={() => clickPage(serie)} className="page-link" rel="prev">
                {serie}
              </a>
            </li>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={`col-6 offset-3 text-right`}>
      {pagy.series.length > 1 && (
        <ul className="pagination justify-content-center">
          <Previous />
          {Middle()}
          <Next />
        </ul>
      )}
    </div>
  );
};

export default Pagination;
