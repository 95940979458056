import React, { useState, useEffect, Component, useMemo } from "react";
import Rails from "@rails/ujs";
import { Modal } from "react-bootstrap";
import TransportCostModal from "./TransportCostModal";
import { TransportCostParams } from "../products/RightPanel";

declare namespace I18n {
  function t(s: string): string;
}

interface TransportCostProps {
  directSellingDomain: boolean;
  transportParams: TransportCostParams;
  currency: string;
}

const TransportCost = (props: TransportCostProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    $('[data-toggle="tooltip"]').tooltip();
  }, []);
  return (
    <div>
      <i
        className="fa fa-question-circle"
        data-toggle="tooltip"
        data-placement="bottom"
        title={I18n.t("shop.calculate_transport_cost")}
        onClick={() => setShowModal(true)}
      ></i>
      <Modal
        show={showModal}
        dialogClassName="modal-lg"
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t("shop.transport_cost")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransportCostModal {...props} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const modalStyles = {
  content: {
    top: "80%",
    left: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "33%",
    maxHeight: "66%",
  },
  overlay: {
    position: "static",
  },
};

export default TransportCost;
