import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { axiosErrorHandler } from "../../../actions/axiosErrors";
import { convertStringPropsToNumberProps } from "../../../actions/functions";
import { ISelectOption } from "../../../actions/types";
import { CatalogAppProps } from "./CatalogApp";
import { selectSelectedLocation, setSelectedLocation } from "./catalogSlice";

interface CatalogRedirectModalProps extends CatalogAppProps {
  setIsOpen: (state: boolean) => void;
}

enum GeolocationPositionCode {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}

function getKeyByValue(value: number) {
  const indexOfS = Object.values(GeolocationPositionCode).indexOf(
    value as unknown as GeolocationPositionCode
  );

  const key = Object.keys(GeolocationPositionCode)[indexOfS];

  return key;
}

interface DirectionResponse {
  code: string;
  routes: Route[];
  waypoints: Waypoint[];
}

interface Route {
  geometry: Geometry;
  legs: Leg[];
  weight_name: string;
  weight: number;
  duration: number;
  distance: number;
}

interface Geometry {
  coordinates: Array<number[]>;
  type: string;
}

interface Leg {
  steps: any[];
  summary: string;
  weight: number;
  duration: number;
  distance: number;
}

interface Waypoint {
  hint: string;
  distance: number;
  name: string;
  location: number[];
}

export interface ReverseGeocodingResponse extends ISelectOption {
  place_id: string;
  licence: string;
  osm_type: string;
  osm_id: string;
  lat: string;
  lon: string;
  display_name: string;
  address: Address;
  boundingbox: string[];
}

interface Address {
  house_number: string;
  village: string;
  municipality: string;
  county: string;
  state: string;
  postcode: string;
  country: string;
  country_code: string;
}

interface Coords {
  longitute: string;
  latitude: string;
  [key: string]: string;
}

const cookieValue: ReverseGeocodingResponse = Cookies.get("selectedLocation")
  ? JSON.parse(Cookies.get("selectedLocation") || "null")
  : null;

const expiresCookie = 1 / 12; // cookie is valid 2 hours

function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}
// haversine formula
const calculateDistanceStraightLine = (
  firstCords: Coords,
  secondCords: Coords
) => {
  const firstCordsNumbers = convertStringPropsToNumberProps<Coords>(firstCords);
  const secondCordsNumbers =
    convertStringPropsToNumberProps<Coords>(secondCords);
  const R = 6371e3; // Earth's radius in meters
  const dLat = deg2rad(
    secondCordsNumbers.latitude - firstCordsNumbers.latitude
  );
  const dLon = deg2rad(
    secondCordsNumbers.longitute - firstCordsNumbers.longitute
  );
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(firstCordsNumbers.latitude)) *
      Math.cos(deg2rad(secondCordsNumbers.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in meters
  return d;
};

const calculateDistanceRouteApi = async (
  firstCords: Coords,
  secondCords: Coords,
  wait: boolean
) => {
  if (wait) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  const url = `https://us1.locationiq.com/v1/directions/driving/${firstCords.longitute},${firstCords.latitude};${secondCords.longitute},${secondCords.latitude}?key=pk.2f15bd0bae6648a520333ffb697bb668&steps=false&geometries=geojson`;
  const response: number = await axios
    .get<DirectionResponse>(url)
    .then((res) => {
      return res.data.routes[0].distance;
    })
    .catch(async (err: Error | AxiosError) => {
      if (
        axios.isAxiosError(err) &&
        err.response &&
        err.response.status === 429
      ) {
        const retryDelay = 1000;
        console.log(`Too many requests. Retrying in ${retryDelay}ms...`);
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        return calculateDistanceRouteApi(firstCords, secondCords, false);
      } else {
        // return high distance if problem with request
        return 999999999;
      }
    });
  return response;
};

const propsToSelectOption = (
  props: CatalogRedirectModalProps
): ISelectOption[] => {
  return props.catalogsInfos.map((d) => {
    return { value: d.url, label: d.country };
  });
};

const emptySelectOption = { value: "", label: "" };

const REDIRECT_STRAIGHT_LINE = true;

const CatalogRedirectModal = (props: CatalogRedirectModalProps) => {
  const dispatch = useDispatch();
  const address = useSelector(selectSelectedLocation) || cookieValue;
  const API_KEY = "pk.2f15bd0bae6648a520333ffb697bb668";
  const [currentShop, setCurrentShop] =
      useState<ISelectOption>(emptySelectOption),
    [shops, setShops] = useState<ISelectOption[]>(propsToSelectOption(props));

  const [geolocationError, setGeolocationError] =
    useState<GeolocationPositionError>();

  useEffect(() => {
    if (address || cookieValue) {
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        reverseGeocoding(position.coords.latitude, position.coords.longitude);
      },
      (error) => {
        setGeolocationError(error);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    async function countryByDistance() {
      const val = await getCountryByDistance();
      setCurrentShop({ value: val.url || "", label: val.country || "" });
    }
    if (address) {
      countryByDistance();
    }
  }, [address]);

  const reverseGeocoding = async (lat: number, lng: number) => {
    const url = `https://us1.locationiq.com/v1/reverse?key=${API_KEY}&lat=${lat}&lon=${lng}&format=json`;
    return await axios
      .get<ReverseGeocodingResponse>(url)
      .then((res) => {
        const data = res.data;
        data.value = data.label = data.display_name;
        dispatch(setSelectedLocation(data));
        Cookies.set("selectedLocation", JSON.stringify(data), {
          expires: expiresCookie,
        });
        return data;
      })
      .catch((err: Error | AxiosError) => {
        // throw err;
        return axiosErrorHandler<ReverseGeocodingResponse>((res) => {
          if (res.type === "axios-error") return res.error.message;
        });
      });
  };

  const autocompleteGeocoding = async (query: string) => {
    const url = `https://us1.locationiq.com/v1/autocomplete?key=${API_KEY}&q=${query}&limit=5&dedupe=1&format=json`;
    return await axios
      .get<ReverseGeocodingResponse[]>(url)
      .then((res) => {
        return res.data;
      })
      .catch((err: Error | AxiosError) => {
        throw err;
        // return axiosErrorHandler<ReverseGeocodingResponse[]>((res) => {
        //   if (res.type === "axios-error") return res.error.message;
        // });
      });
  };

  const _loadOptions = (
    inputValue: string,
    callback: (options: ReverseGeocodingResponse[]) => void
  ) => {
    setTimeout(async () => {
      const data = await autocompleteGeocoding(inputValue);
      const options = data.map((d) => {
        return { ...d, value: d.display_name, label: d.display_name };
      });
      callback(options);
    }, 1000);
  };

  const loadOptions = debounce(_loadOptions, 1000);

  const getCountryByDistance = async () => {
    const promisesArr = props.catalogsInfos.map(async (v, i) => {
      return {
        distance: REDIRECT_STRAIGHT_LINE
          ? calculateDistanceStraightLine(
              { latitude: address.lat, longitute: address.lon },
              { latitude: v.latitude, longitute: v.longitute }
            )
          : await calculateDistanceRouteApi(
              { latitude: address.lat, longitute: address.lon },
              { latitude: v.latitude, longitute: v.longitute },
              i % 2 === 1
            ),
        url: v.url,
        country: v.country,
      };
    });
    const resArr = await Promise.all(promisesArr);
    const objectWithMinValue = resArr.reduce((minObj, currentObj) => {
      return currentObj.distance < minObj.distance ? currentObj : minObj;
    }, resArr[0]);
    return objectWithMinValue
      ? { url: objectWithMinValue.url, country: objectWithMinValue.country }
      : {};
  };

  const clickRedirect = () => {
    const domain = props.catalogsInfos.find((ci) => {
      return ci.url === currentShop.value;
    });
    if (!domain) {
      return;
    }
    window.location.href = domain.url;
  };

  const clickRedirectDeprecated = async () => {
    const promisesArr = props.catalogsInfos.map(async (v, i) => {
      return {
        distance: REDIRECT_STRAIGHT_LINE
          ? calculateDistanceStraightLine(
              { latitude: address.lat, longitute: address.lon },
              { latitude: v.latitude, longitute: v.longitute }
            )
          : await calculateDistanceRouteApi(
              { latitude: address.lat, longitute: address.lon },
              { latitude: v.latitude, longitute: v.longitute },
              i % 2 === 1
            ),
        url: v.url,
      };
    });
    const resArr = await Promise.all(promisesArr);
    const objectWithMinValue = resArr.reduce((minObj, currentObj) => {
      return currentObj.distance < minObj.distance ? currentObj : minObj;
    }, resArr[0]);
    const redirectUrl = objectWithMinValue
      ? `${objectWithMinValue.url}${window.location.pathname}`
      : null;
    if (redirectUrl) {
      window.location.href = `${window.location.protocol}//${redirectUrl}`;
    }
  };

  return (
    <>
      <div className="modal-header">
        <h4>{I18n.t("shop.where_are_you_from")}</h4>
        <button
          className="close"
          type="button"
          onClick={() => props.setIsOpen(false)}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        {geolocationError ? (
          <>
            <p>
              {I18n.t("shop.geolocation_error")}
              <i
                className="fa fa-question-circle"
                data-toggle="tooltip"
                data-placement="bottom"
                title={I18n.t(`shop.${getKeyByValue(geolocationError.code)}`)}
              ></i>
            </p>
            <p>{I18n.t("shop.manual_search")}</p>
          </>
        ) : (
          <p>{I18n.t("shop.geolocation_success")}</p>
        )}
        <p>{address ? address.display_name : ""}</p>
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          placeholder={I18n.t("shop.search_place")}
          defaultValue={address}
          value={address}
          onChange={(e: ReverseGeocodingResponse) => {
            dispatch(setSelectedLocation(e));
            Cookies.set("selectedLocation", JSON.stringify(e), {
              expires: expiresCookie,
            });
          }}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {!!address && (
          <div className="mt-2">
            <p>{I18n.t("shop.our_closest_shop")}</p>
            {I18n.t("shop.online_store")}: {currentShop.label}
            <div className="search-region w-100">
              <Select
                options={shops}
                placeholder={I18n.t("shop.search_place")}
                value={currentShop}
                onChange={(e: ISelectOption) => {
                  setCurrentShop(e);
                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </div>
          </div>
        )}
        {address && (
          <button
            className="btn btn--ultrablue float-right mt-4"
            onClick={clickRedirect}
          >
            {I18n.t("shop.go_to_shop")}
          </button>
        )}
      </div>
    </>
  );
};

export default CatalogRedirectModal;
