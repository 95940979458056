import Axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ISelectOption } from "../../../actions/types";
import { TransportCostParams } from "../products/RightPanel";

declare namespace I18n {
  function t(s: string): string;
}

declare function prefixURL(url: string): string;

interface TransportCostModalProps {
  directSellingDomain: boolean;
  transportParams: TransportCostParams;
  currency: string;
}

const TransportCostModal = (props: TransportCostModalProps) => {
  const [countries, setCountries] = useState(new Array<ISelectOption>()),
    [selectedCountry, setSelectedCountry] = useState<ISelectOption | null>(
      null
    ),
    [regions, setRegions] = useState(new Array<ISelectOption>()),
    [selectedRegion, setSelectedRegion] = useState<ISelectOption | null>(null),
    [cost, setCost] = useState<string | null>(null),
    [vatCost, setVatCost] = useState<string | null>(null),
    [netCost, setNetCost] = useState<string | null>(null),
    [costLoading, setCostLoading] = useState<boolean | null>(null),
    handleCountryChange = (option: ISelectOption) => {
      setSelectedCountry(option);
      getRegions(option.value);
    },
    handleRegionChange = (option: ISelectOption) => {
      setSelectedRegion(option);
      getTransportCost(
        option.value,
        selectedCountry ? selectedCountry.value : ""
      );
    };

  useEffect(() => {
    getCountries();
  }, []);

  const getTransportCost = (region: string, country: string) => {
    setCostLoading(true);
    Axios.post<{ cost: string | null; vat: string | null; net: string | null }>(
      prefixURL("/api/transport_costs/cost"),
      {
        country,
        region: region,
        weight: props.transportParams.weight,
        palette_count: props.transportParams.palette_count,
        length_dm: props.transportParams.length_dm,
      },
      {
        headers: {
          accept: "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data) {
          if (
            res.data.cost !== null &&
            res.data.vat !== null &&
            res.data.net !== null
          ) {
            setCost(parseFloat(res.data.cost).toFixed(2));
            setVatCost(parseFloat(res.data.vat).toFixed(2));
            setNetCost(parseFloat(res.data.net).toFixed(2));
          } else {
            setCost(null);
          }
        } else {
        }
      })
      .catch((err) => {
        setCost(null);
        console.log(err);
      })
      .finally(() => {
        setCostLoading(false);
      });
  };

  const getCountries = () => {
    Axios.get<string[]>(prefixURL("/api/transport_costs/countries"), {
      headers: {
        accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data) {
          const options = res.data.map((s) => {
            return { label: s, value: s };
          });
          setCountries(options);
          if (options.length === 1) {
            handleCountryChange(options[0]);
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRegions = (country: string) => {
    Axios.get<string[]>(
      prefixURL(`/api/transport_costs/regions?country=${country}`),
      {
        headers: {
          accept: "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data) {
          const options = res.data.map((s) => {
            return { label: s, value: s };
          });
          setRegions(options);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!countries.length) {
    return <h5>{I18n.t("shop.transport_cost_desc")}</h5>;
  }

  return (
    <div>
      <h6>{I18n.t("shop.transport_cost_desc")}</h6>
      <div className="d-flex align-items-baseline mt-4">
        <div className="w-50">
          <label>{I18n.t("shop.transport_country")}</label>
          <Select
            isDisabled={countries.length === 1 && props.directSellingDomain}
            placeholder={I18n.t("shop.transport_country")}
            options={countries}
            defaultValue={null}
            value={selectedCountry}
            onChange={handleCountryChange}
          />
        </div>
        {!!regions.length && (
          <div className="w-50 ml-4">
            <label>{I18n.t("shop.transport_region")}</label>
            <Select
              placeholder={I18n.t("shop.transport_region")}
              options={regions}
              defaultValue={null}
              value={selectedRegion}
              onChange={handleRegionChange}
            />
          </div>
        )}
      </div>
      <div className="transport-cost-modal mt-4">
        {costLoading !== null &&
          (costLoading ? (
            <i className="fa fa-spinner fa-spin fa-7x fa-align-center"></i>
          ) : (
            <>
              <h1>
                <span className="transport-cost-label">
                  {I18n.t("shop.transport_cost")}:{" "}
                </span>
                <span className="transport-cost">
                  {cost === null ? (
                    I18n.t("shop.transport_cost_individual")
                  ) : (
                    <>
                      {cost} {props.currency} **
                      <p className="transport-cost-desc mb-0">
                        ({I18n.t("shop.include_vat")} {vatCost} {props.currency}{" "}
                        {I18n.t("shop.vat")})
                      </p>
                      <p className="transport-cost-desc">
                        ({I18n.t("shop.net")}: {netCost} {props.currency})
                      </p>
                      <p className="transport-cost-desc">
                        ** {I18n.t("shop.transport_cost_desc_price")}
                      </p>
                    </>
                  )}
                </span>
              </h1>
            </>
          ))}
      </div>
    </div>
  );
};

export default TransportCostModal;
