import React from "react";
import { Controller, get } from "react-hook-form";

import SelectCountryRegion from "../SelectCountryRegion";
import TextField from "./TextField";
import {
  TransactionAdressesProps,
  TransactionFormProps
} from "./TransactionForm";

interface BillingAddressProps
  extends TransactionAdressesProps,
  TransactionFormProps { }

const BillingAddress = (props: BillingAddressProps) => {
  const emailClass = props.currentUser.guest ? "col-lg-4 col-md-6 col-sm-6" : "col-lg-6 col-md-6 col-sm-6"
  const addressClass = props.currentUser.guest ? "col-lg-4 col-md-6 col-sm-6" : "col-lg-4 col-md-12 col-sm-12"
  return (
    <div className="container">
      <div className="cart checkout top-section">
        <div className="top-section__inner">
          <h2 id="billing_address_header_anchor">
            {I18n.t("shop.billing_address")}
          </h2>
          <div className="cart__table row mx-0">
            <span className="cart__title">{I18n.t("shop.address")}</span>
            <div className="col-lg-12 my-2"></div>
            <div className={`form-group ${emailClass} required`}>
              <Controller
                control={props.control}
                name="billing_first_name"
                defaultValue=""
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      errors={props.errors}
                      placeholder={I18n.t("shop.required_first_name")}
                      error={!!get(props.errors, "billing_first_name")}
                    />
                  );
                }}
              />
            </div>
            <div className={`form-group ${emailClass} required`}>
              <Controller
                control={props.control}
                name="billing_last_name"
                defaultValue=""
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      errors={props.errors}
                      placeholder={I18n.t("shop.required_last_name")}
                      error={!!get(props.errors, "billing_last_name")}
                    />
                  );
                }}
              />
            </div>
            {props.currentUser.guest &&
              (<div className="form-group col-lg-4 col-md-6 col-sm-6 required">
                <Controller
                  control={props.control}
                  name="billing_email"
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        errors={props.errors}
                        placeholder={I18n.t("shop.required_email")}
                        error={!!get(props.errors, "billing_email")}
                      />
                    );
                  }}
                />
              </div>)}
            <div className="form-group col-lg-6 col-md-6 col-sm-6 required">
              <Controller
                control={props.control}
                name="billing_phone"
                defaultValue=""
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      errors={props.errors}
                      placeholder={I18n.t("shop.required_phone")}
                      error={!!get(props.errors, "billing_phone")}
                    />
                  );
                }}
              />
            </div>
            <div className="form-group col-lg-6 col-md-6 col-sm-6">
              <Controller
                control={props.control}
                name="billing_company"
                defaultValue=""
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      errors={props.errors}
                      label={I18n.t("shop.company_name")}
                      placeholder={I18n.t("shop.company_name")}
                    />
                  );
                }}
              />
            </div>
            <div className={`form-group ${addressClass} required`}>
              <Controller
                control={props.control}
                name="billing_address_1"
                defaultValue=""
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      errors={props.errors}
                      label={I18n.t("shop.address")}
                      placeholder={I18n.t("shop.required_address_1")}
                      error={!!get(props.errors, "billing_address_1")}
                      className="mb-0"
                    />
                  );
                }}
              />
              <div className="form-group col-lg-12 row mx-0 px-0 required">
                <div className="px-0 col-lg-6 col-md-6 col-sm-6 col-6">
                  <Controller
                    control={props.control}
                    name="billing_address_2"
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          errors={props.errors}
                          label=""
                          className="py-0 my-0"
                          placeholder={I18n.t("shop.address_2")}
                          error={!!get(props.errors, "billing_address_2")}
                        />
                      );
                    }}
                  />
                </div>
                <div className="px-0 col-lg-6 col-md-6 col-6 col-sm-6 col-6">
                  <Controller
                    control={props.control}
                    name="billing_address_3"
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          errors={props.errors}
                          label=""
                          className="py-0 my-0"
                          placeholder={I18n.t("shop.address_3")}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 row mx-0 px-0">
              <div className="form-group col-lg-6 col-md-6 col-sm-6 required">
                <Controller
                  control={props.control}
                  name="billing_city"
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        errors={props.errors}
                        placeholder={I18n.t("shop.required_city")}
                        error={!!get(props.errors, "billing_city")}
                      />
                    );
                  }}
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 col-sm-6 required">
                <Controller
                  control={props.control}
                  name="billing_zip_code"
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        errors={props.errors}
                        placeholder={I18n.t("shop.required_zip_code")}
                      />
                    );
                  }}
                />
              </div>
              {!props.getValues("shipping_address_different_than_billing") ? (
                <div className="col-lg-12 transport-info">
                  <SelectCountryRegion {...props} />
                </div>
              ) : (
                <>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 country">
                    <Controller
                      control={props.control}
                      name="billing_country"
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            errors={props.errors}
                            label={I18n.t("shop.country")}
                            placeholder={I18n.t("shop.country")}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 state">
                    <Controller
                      control={props.control}
                      name="billing_state"
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            errors={props.errors}
                            label={I18n.t("shop.state")}
                            placeholder={I18n.t("shop.state")}
                          />
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="form-group col-lg-12">
              <h5>{I18n.t("shop.comments")} :</h5>
              <br />
              <Controller
                control={props.control}
                name="comments"
                defaultValue=""
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      errors={props.errors}
                      type="textarea"
                      label=""
                      placeholder={I18n.t("shop.comments_write")}
                    />
                  );
                }}
              />
            </div>
            <div className="custom-control custom-checkbox pb-3 mt-4 col-lg-12">
              <Controller
                control={props.control}
                name="shipping_address_different_than_billing"
                defaultValue={false}
                render={({ field }) => {
                  return (
                    <>
                      <input
                        {...field}
                        id="shipbillingaddress"
                        className="custom-control-input"
                        type="checkbox"
                        name="order[shipping_address_different_than_billing]"
                        data-validate="true"
                        value={field.value ? "1" : "0"}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="shipbillingaddress"
                      >
                        {I18n.t("shop.ship_to_different_address")}
                      </label>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BillingAddress;
