import React from "react";
import { Controller, get } from "react-hook-form";
import SelectCountryRegion from "../SelectCountryRegion";
import TextField from "./TextField";
import {
  TransactionAdressesProps,
  TransactionFormProps
} from "./TransactionForm";

interface ShippingAddressProps
  extends TransactionAdressesProps,
  TransactionFormProps { }

const ShippingAddress = (props: ShippingAddressProps) => {
  return (
    <div id="shipping_address">
      <div className="cart checkout top-section">
        <div className="container">
          <div className="top-section__inner">
            <h2 id="shipping_address_header_anchor">
              {I18n.t("shop.shipping_address")}
            </h2>
            <div className="cart__table row mx-0">
              <span className="cart__title">{I18n.t("shop.address")}</span>
              <div className="col-lg-12 my-1"></div>
              <div className="card-body row">
                <div className="form-group required form-group col-lg-4 col-md-6 col-sm-6">
                  <Controller
                    control={props.control}
                    name="shipping_first_name"
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          errors={props.errors}
                          placeholder={I18n.t("shop.required_first_name")}
                          error={!!get(props.errors, "shipping_first_name")}
                        />
                      );
                    }}
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6 col-sm-6 required">
                  <Controller
                    control={props.control}
                    name="shipping_last_name"
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          errors={props.errors}
                          placeholder={I18n.t("shop.required_last_name")}
                          error={!!get(props.errors, "shipping_last_name")}
                        />
                      );
                    }}
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6 col-sm-6">
                  <Controller
                    control={props.control}
                    name="shipping_company"
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          errors={props.errors}
                          label={I18n.t("shop.company_name")}
                          placeholder={I18n.t("shop.company_name")}
                        />
                      );
                    }}
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6 col-sm-6 required">
                  <Controller
                    control={props.control}
                    name="shipping_address_1"
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          errors={props.errors}
                          label={I18n.t("shop.address")}
                          placeholder={I18n.t("shop.required_address_1")}
                          error={!!get(props.errors, "shipping_address_1")}
                          className="mb-0"
                        />
                      );
                    }}
                  />
                  <div className="form-group col-lg-12 row mx-0 px-0 required">
                    <div className="px-0 mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                      <Controller
                        control={props.control}
                        name="shipping_address_2"
                        defaultValue=""
                        render={({ field }) => {
                          return (
                            <TextField
                              {...field}
                              errors={props.errors}
                              label=""
                              className="my-0 py-0"
                              placeholder={I18n.t("shop.address_2")}
                              error={!!get(props.errors, "shipping_address_2")}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="px-0 col-lg-6 col-md-6 col-sm-6 col-6">
                      <Controller
                        control={props.control}
                        name="shipping_address_3"
                        defaultValue=""
                        render={({ field }) => {
                          return (
                            <TextField
                              {...field}
                              errors={props.errors}
                              label=""
                              className="py-0 my-0"
                              placeholder={I18n.t("shop.address_3")}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12 row mx-0 px-0">
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 required">
                    <Controller
                      control={props.control}
                      name="shipping_city"
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            errors={props.errors}
                            placeholder={I18n.t("shop.required_city")}
                            error={!!get(props.errors, "shipping_city")}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 required">
                    <Controller
                      control={props.control}
                      name="shipping_zip_code"
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            errors={props.errors}
                            placeholder={I18n.t("shop.required_zip_code")}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="col-lg-12 transport-info">
                    <SelectCountryRegion {...props} />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 country"></div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 state"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingAddress;
