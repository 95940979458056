import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./productSlice";
import catalogReducer from "../catalog/catalogSlice";

export const store = configureStore({
  reducer: {
    product: productReducer,
    catalog: catalogReducer,
  },
});
